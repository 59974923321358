import { ReactComponent as ServiceClosureIcon } from '../assets/svg/service-closure-icon.svg';
const ServiceClosureBanner = () => {
  return (
    <div className='service__closure-banner'>
      <div className='service__closure-text--container'>
        <p className='service__closure-text'>
          China Import service is closed from{' '}
          <strong>January 23 - February 8, 2025</strong> due to the Chinese New
          Year Holiday.
        </p>
      </div>
      <ServiceClosureIcon className='service__closure-icon' />
    </div>
  );
};

export default ServiceClosureBanner;
