import ShopnshipBoxes from '../../assets/image/shopnship-boxes.png';
import Close from '../../assets/svg/close.svg';
import Download from '../../assets/svg/download.svg';
import { useRef } from 'react';
import RoundupLogo from '../../assets/image/roundup-logo-small.png';
import { userRoundUpDataState } from '../../recoil/atoms';
import { useRecoilState } from 'recoil';
import ShareButtons from '../Commons/share-buttons';
import { serviceTypesEnum } from '../../utilities/enums/service-types.enum';

const ShopnshipRoundUpPrompt = ({
  openPrompt,
  setOpenPrompt,
  setOpenMainPrompt,
  downloadDisplay,
  setDownloadDisplay,
  getImage,
  handleShareLink,
  closeSocials
}) => {
  const [roundupData] = useRecoilState(userRoundUpDataState);

  const topService = roundupData?.topServicesUsed.reduce((max, item) =>
    max.count > item.count ? max : item
  ).service;

  const componentRef = useRef();

  const closeCurrentPrompt = () => {
    setOpenPrompt(false);
    setOpenMainPrompt(true);
    setDownloadDisplay(false);
  };

  return (
    <>
      <div className={`round-up__prompt fade${openPrompt ? ' show' : ''}`}>
        <div className='round-up__prompt__wrap'>
          <div className={`${downloadDisplay ? 'whiteBg' : ''}`}>
            <div
              className={`shopnship-total__body ${
                downloadDisplay ? 'small' : ''
              }`}
            >
              <div className='padded-flex'>
                <img
                  onClick={() => getImage(componentRef.current)}
                  style={{ cursor: 'pointer' }}
                  src={Download}
                  alt='download'
                />
                <img
                  onClick={closeCurrentPrompt}
                  style={{ cursor: 'pointer' }}
                  src={Close}
                  alt='close'
                />
              </div>
              <div ref={componentRef} className='shopnship-total__body__wrap'>
                <div className='shopnship-total__shipped-total'>
                  <div className='shipped-content'>
                    <div>
                      <img
                        src={RoundupLogo}
                        alt='round up logo'
                        className='roundup-logo-small'
                      />
                      <h5
                        className={`content-text-${
                          downloadDisplay ? 'small' : 'large-rate'
                        }`}
                      >
                        Your <br /> favorite <br /> feature on
                        <br /> Topship was
                        <br />
                        <span
                          style={{
                            whiteSpace: 'break-spaces',
                            wordBreak: 'break-word',
                            textTransform: 'capitalize'
                          }}
                        >
                          {serviceTypesEnum[topService]}
                        </span>
                      </h5>
                    </div>
                  </div>
                  <div className='roundup-total-img'>
                    <img src={ShopnshipBoxes} alt='gift boxes' />
                  </div>
                </div>
              </div>
            </div>
            {downloadDisplay && (
              <ShareButtons
                handleShareLink={handleShareLink}
                closeSocials={closeSocials}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={`round-up__prompt__backdrop fade${
          openPrompt ? ' show' : ''
        }`}
      ></div>
    </>
  );
};

export default ShopnshipRoundUpPrompt;
