import React, { useEffect, useRef, useState } from 'react';
import { currencies as currencyList } from '../../constants/currencies';
import { useGetForeignRates } from '../../operations/queries';
import BusyOverlay from '../busy-overlay';
import { InfoOutlined } from '@mui/icons-material';
import { isElementInViewport } from '../../utilities/is-element-in-viewport';

const currencyIndices = {
  [currencyList[0]?.currency]: 0,
  [currencyList[1]?.currency]: 1,
  [currencyList[2]?.currency]: 2
};

const SelectCurrencyPrompt = ({
  onPromptClose,
  openPrompt = false,
  hideCurrencies = [],
  onOpenPaymentPrompt = () => {},
  setOpenPrompt = (bool = false) => {}
}) => {
  const [currencies, setCurrencies] = useState(currencyList);
  const [currencyState, setCurrency] = useState({
    rate: 1,
    currency: currencyList[0].currency
  });
  const [getForeignRates, getForeignRatesResult] = useGetForeignRates();
  const [shouldRemoveMargin, setShouldRemoveMargin] = useState(false);
  const successButton = useRef();

  const onClose = () => {
    setOpenPrompt(false);

    onPromptClose?.();
  };

  const handleCurrency = async currency => {
    if (currency !== currencies[0].currency) {
      if (!currencies[currencyIndices[currency]].rate) {
        const { data } = await getForeignRates(currency);

        if (data?.finalAmount) {
          currencies[currencyIndices[currency]].rate = data?.finalAmount;

          setCurrencies([...currencies]);
        }
      }
    }

    setCurrency({
      currency,
      rate: currencies[currencyIndices[currency]]?.rate || 1
    });
  };

  const handleSuccess = () => {
    onOpenPaymentPrompt(currencyState);
  };

  useEffect(() => {
    if (successButton?.current) {
      setShouldRemoveMargin(!isElementInViewport(successButton?.current));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerHeight, successButton?.current]);

  return (
    <>
      <div
        className={`new-shop-n-ship__prompt fade${openPrompt ? ' show' : ''}`}
        style={{
          marginTop: '2rem'
        }}
      >
        <div className='new-shop-n-ship__prompt__wrap'>
          <div className='new-shop-n-ship__prompt__header'>
            <h2>Payment Method</h2>
          </div>
          <div className='new-shop-n-ship__prompt__body'>
            <div className='new-shop-n-ship__prompt__body__wrap'>
              <div className='new-shop-n-ship__prompt__body__options'>
                <div className='new-shop-n-ship__prompt__body__options__wrap'>
                  <div className='new-shop-n-ship__prompt__body__options__header'>
                    <span>
                      Select currency to find other payment methods to pay with:
                    </span>
                  </div>
                  <div className='new-shop-n-ship__prompt__body__options__list'>
                    {currencies
                      .filter(curency => {
                        if (hideCurrencies.length) {
                          return !hideCurrencies.includes(curency.currency);
                        }

                        return true;
                      })
                      .map(currency => (
                        <div
                          key={currency.countryCode}
                          onClick={() => handleCurrency(currency.currency)}
                          className={`new-shop-n-ship__prompt__body__options__list__item${
                            currency.currency === currencyState?.currency
                              ? ' active'
                              : ''
                          }`}
                        >
                          <div className='new-shop-n-ship__prompt__body__options__list__item__wrap'>
                            <div className='new-shop-n-ship__prompt__body__options__list__item__name d-flex flex-column'>
                              <h3
                                style={{
                                  color:
                                    currency.currency ===
                                    currencyState?.currency
                                      ? '#22428f'
                                      : '#0F1414',
                                  fontWeight: 600,
                                  textTransform: 'capitalize'
                                }}
                              >
                                {`${currency.logo} ${currency.name} (${currency.currency})`}
                              </h3>
                              {currency?.rate && (
                                <p
                                  style={{
                                    color: '#545859',
                                    fontSize: '14px',
                                    fontWeight: '500'
                                  }}
                                >
                                  Exchange Rate:{' '}
                                  <span style={{ fontWeight: '600' }}>
                                    1 {currency.currency} = {currency?.rate}
                                  </span>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className='new-shop-n-ship__prompt__body__options__notice'>
                    <div className='new-shop-n-ship__prompt__body__options__notice__wrap'>
                      <InfoOutlined
                        sx={{
                          color: '#416eb2',
                          transform: 'scale(1.2)',
                          marginTop: '3px'
                        }}
                      />
                      <p>
                        Exchange rate could change if payment isn’t made
                        immediately / within window.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='new-shop-n-ship__prompt__footer'>
            <div className='new-shop-n-ship__prompt__footer__wrap'>
              <div className='new-shop-n-ship__prompt__footer__actions'>
                <button onClick={onClose}>Close</button>
                <button
                  ref={successButton}
                  disabled={!currencyState?.currency}
                  onClick={handleSuccess}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
        <BusyOverlay loading={getForeignRatesResult.isLoading} />
      </div>

      <div
        className={`new-shop-n-ship__prompt__backdrop fade${
          openPrompt ? ' show' : ''
        }`}
      ></div>
    </>
  );
};

export default SelectCurrencyPrompt;
