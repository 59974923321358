import React, { useState, useRef, useEffect } from 'react';
import roundUpSocials from '../../constants/roundup-socials';
import roundUpText from '../../assets/image/round-up-text.webp';
import { ReactComponent as CloseIcon } from '../../assets/svg/close-roundup-icon.svg';
import planeIcon from '../../assets/image/top-plane-icon-two.webp';
import planeIconTwo from '../../assets/image/top-plane-icon.webp';
import nairaCircle from '../../assets/image/naira-circle-icon.webp';
import stickyNote from '../../assets/image/sticky-note-icon.webp';
import stickyNoteStar from '../../assets/image/sticky-note-star.webp';
import carton from '../../assets/image/round-up-box-icon.webp';
import ShareButtons from '../Commons/share-buttons';
import { userRoundUpDataState } from '../../recoil/atoms';
import { useRecoilState } from 'recoil';
import shortNumber from 'short-number';
import { toCurrency } from '../../utilities/to-currency';

const YearRoundUp = ({
  openPrompt,
  setOpenPrompt,
  downloadDisplay,
  setDownloadDisplay,
  getImage,
  handleShareLink,
  imageUrl,
  setImageUrl,
  closeSocials
}) => {
  const [roundUpData] = useRecoilState(userRoundUpDataState);

  const [isDownload, setIsDownload] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const componentRef = useRef();

  const closeCurrentPrompt = () => {
    setOpenPrompt(false);
    setDownloadDisplay(false);
    setImageUrl(null);
  };
  const handleShareOnly = () => {
    setIsShare(true);
    getImage(componentRef.current);
  };
  const handleDownloadOnly = () => {
    setIsDownload(true);
    setIsShare(false);
    getImage(componentRef.current);
    if (imageUrl) {
      handleShareLink(roundUpSocials.download);
    }
  };

  useEffect(() => {
    if (imageUrl && isDownload) {
      handleShareLink(roundUpSocials.download);
      setIsDownload(false);
    }
  }, [imageUrl]);
  return (
    <div
      className={`Year__roundup-container fade${openPrompt ? ' show' : ''}`}
      style={{
        transition: 'all 0.3s ease',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '1000000'
      }}
    >
      <div className='year__roundup-content--container'>
        <div className='heading__and__close-icon'>
          <p className='year__roundup-heading'>2024 Roundup</p>
          <CloseIcon
            onClick={closeCurrentPrompt}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className='year__roundup-content--box'>
          <div className='year__roundup-content' ref={componentRef}>
            <div className='round__up-text'>
              <img src={roundUpText} alt='' className='plane__icon-img' />
            </div>

            <div className='round__up-plane--one'>
              <img
                src={planeIconTwo}
                alt='topship-plane'
                className='plane__icon-img'
              />
            </div>
            <div className='round__up-boxes'>
              <div className='round__up-first--boxes'>
                <div className='round__up-box--one'>
                  <p className='round__up-main--text main__text-one'>
                    No. of packages received
                  </p>
                  <p className='round__up-sub--text'>
                    {shortNumber(roundUpData?.numberOfPackagesReceived)}
                  </p>{' '}
                  <div className='round__up-plane--two'>
                    <img
                      src={planeIcon}
                      alt='topship-plane'
                      className='plane__icon-img'
                    />
                  </div>
                </div>
                <div className='round__up-box--two'>
                  <div className='naira__circle-icon'>
                    <img
                      src={nairaCircle}
                      alt='naira-sign'
                      className='plane__icon-img'
                    />
                  </div>
                  <p className='round__up-main--text second__box-text'>
                    Value of
                    <br />
                    packages shipped{' '}
                  </p>
                  <p className='round__up-sub--text sub__text-three'>
                    {`${toCurrency(0).charAt(0)}${shortNumber(
                      roundUpData?.valueOfPackagesShipped
                    )}`}
                  </p>
                </div>
              </div>
              <div className='round__up-second--boxes'>
                <div className='round__up-box--three'>
                  <p className='round__up-main--text second__box-text'>
                    No. of packages <br />
                    sent
                  </p>
                  <div className='carton__box-icon'>
                    <img
                      src={carton}
                      alt='carton-box'
                      className='plane__icon-img'
                    />
                  </div>
                  <p className='round__up-sub--text sub__text-three'>
                    {shortNumber(roundUpData?.numberOfPackagesSent)}
                  </p>
                </div>
                <div className='round__up-box--four'>
                  <p className='round__up-main--text main__text-four'>
                    City you shipped to <br /> the most
                  </p>
                  <p
                    style={{ textTransform: 'capitalize' }}
                    className='round__up-sub--text sub__text-four'
                  >
                    {roundUpData?.topCitiesShippedTo?.[0]?.city?.toLowerCase()}
                  </p>
                  <div className='sticky__note-icon'>
                    <img
                      src={stickyNote}
                      alt='sticky-note'
                      className='plane__icon-img'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='go__to-service'>
              <p className='go__to-service--label'>Your go-to service</p>
              <p className='go__to-service--text'>
                {roundUpData?.topServicesUsed?.[0]?.service === 'Shopnship'
                  ? 'Shop & Ship'
                  : roundUpData?.topServicesUsed?.[0]?.service}
              </p>
              <div className='go__to-service--icon'>
                <img
                  src={stickyNoteStar}
                  alt='sticky-note-star'
                  className='plane__icon-img'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='year__roundup-ctas'>
          <button className='round__up-save--img' onClick={handleDownloadOnly}>
            Save Image{' '}
          </button>
          <button className='round__up-share--img' onClick={handleShareOnly}>
            Share{' '}
          </button>
          {downloadDisplay && isShare && (
            <div className='share__buttons-container'>
              <ShareButtons
                handleShareLink={handleShareLink}
                closeSocials={closeSocials}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default YearRoundUp;
