import React from 'react';
import { ReactComponent as CloseIcon } from '../assets/svg/close-roundup-icon.svg';
import { shopnshipFaqQuestions } from '../constants/faq-questions';
import HowItWorksAccordion from './how-it-works-accordion';

const HowItWorks = ({ openHowItWorks, setOpenHowItWorks }) => {
  return (
    <div
      className={`Year__roundup-container fade${openHowItWorks ? ' show' : ''}`}
      style={{
        transition: 'all 0.3s ease',
        justifyContent: 'flex-start'
      }}
    >
      <div className='how__it-works--content'>
        <div className='how__it-works--heading-box'>
          <p className='how__it-works--heading'>How it works</p>
          <CloseIcon
            onClick={() => setOpenHowItWorks(false)}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className='how__it-works--accordions-container'>
          {shopnshipFaqQuestions.map((content, index) => {
            return <HowItWorksAccordion key={index} content={content} />;
          })}
        </div>
        <div className='how__it-works--cta-box'>
          <button
            className='how__it-works--cta'
            onClick={() => setOpenHowItWorks(false)}
          >
            continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
