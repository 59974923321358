const shipmentRateModeDictionary = value => {
  const shipmentModes = {
    express: 'Express',
    budget: 'Budget',
    standard: 'Standard',
    premium: 'Premium',
    plus: 'Premium',
    upseconomy: 'UPSEconomy',
    upsexpress: 'UPSExpress',
    upsexpedited: 'UPSExpedited',
    fedex: 'FedEx',
    regular: 'Regular',
    lastmilebudget: 'LastMileBudget',
    gig: 'GIG',
    saverpriority: 'SaverPriority',
    seaexport: 'SeaExport',
    dellyman: 'Dellyman',
    errandlr: 'Errandlr'
  };

  let selectedMode = '';

  Object.keys(shipmentModes).forEach(mode => {
    if (value?.toLowerCase().includes(mode)) {
      selectedMode = shipmentModes[mode];
    }
  });
  return selectedMode.length ? selectedMode : 'Mode Unavailable';
};

export default shipmentRateModeDictionary;
