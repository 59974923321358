import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import { Alert, Button, InputAdornment, Skeleton, Radio } from '@mui/material';
import {
  multipleShipmentRatesState,
  saveShipmentDetailsState,
  multipleRateLoadingState,
  multipleShipmentCounponListState,
  savePickupRateState,
  selectedPickupPartnerState
} from '../../../../recoil/atoms';
import { multipleShipmentLinks } from '../../../../constants/shipment-links';
import { toCurrency } from '../../../../utilities/to-currency';
import {
  useGetCouponQuery,
  useGetPickupRatesQuery,
  useGetShipmentRateQueryMultiple
} from '../../../../operations/queries';
import NewShippingStepsLayout from '../../../../components/Layouts/new-shipping-steps-layout';
import shipmentRateModeDictionary from '../../../../constants/shipment-rate-modes-dictionary';
import SubmitButton from '../../../../components/submit-button';
import insurancePlans, {
  extendedInsuranceLimits
} from '../../../../constants/insurance-plans';
import { useSendAlert } from '../../../../utilities/send-alert';
import { Edit, RemoveCircle } from '@mui/icons-material';
import { calculateTotalCost } from '../../../../utilities/calculate-total-cost';
import { applyCouponToPrice } from '../../../../utilities/apply-coupon-to-price';
import Input from '../../../../components/new-dashboard/custom-input';
import { checkIsCompleted } from '../../helper-functions';
import { shipmentMethods } from '../../../../constants/book-shipment-contants';
import { useMemo } from 'react';
import EmptyList from '../../../../components/empty-list';
import getPlan from '../../../../utilities/getPlans';
import emptyStateIcon from '../../../../assets/svg/empty.svg';
import { ExpandMore } from '@mui/icons-material';
import {
  shippingRates,
  lightColors,
  statesWithTopshipHubs
} from '../../../../constants/shipping-rates';
import {
  BlueCheck,
  SelectArrowBlue
} from '../../../../constants/asset-contants';
import { toFixed } from '../../../../utilities/to-fixed';
import { isItemCollectionModeMatch } from '../../../../utilities/check-item-collection-mode';
import omit from 'lodash.omit';
import { pickupPartnersWithBadImages } from '../../../../constants/shipment-status-dictionary';
import { ReceiverStepsComponent } from '../../../../components/Commons/summary-component';
import { DeliveryOptions1 } from '../../../../components/shipping-options';
import { TermsAndConditionsPrompt } from '../../../../constants/terms-&-condition';
import InAppSaverPrompt from '../../../../components/modals/in-app-prompt';

const MultipleDeliveryOptions = () => {
  const history = useHistory();

  const sendAlert = useSendAlert();
  const [multipleShipmentRates] = useRecoilState(multipleShipmentRatesState);
  const [multipleRateLoading] = useRecoilState(multipleRateLoadingState);
  const [saveShipmentDetail, setSaveShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );

  const { multipleReceivers, senderDetail, pickupDate } = saveShipmentDetail;
  const initialReceiverSteps = Array.from(
    { length: multipleReceivers.length },
    (_, index) => ({
      index,
      isActive: index === 0
    })
  );
  const [selectedBudgetDelivery, setSelectedBudgetDelivery] = useState(
    multipleReceivers.map((rate, index) => ({
      budgetDeliveryCost: null
    }))
  );
  const [selectedPickupPartner, setSelectedPickupPartner] = useRecoilState(
    selectedPickupPartnerState
  );
  const [openToggle, setOpenToggle] = useState(
    multipleReceivers.map((rate, index) => ({
      delivery: true,
      pickup: true,
      insurance: true
    }))
  );

  const [openInAppPrompt, setOpenInAppPrompt] = useState(false);
  const [receiverSteps, setReceiverSteps] = useState(initialReceiverSteps);
  const [openSaverAlert, setOpenSaverAlert] = useState(false);

  const defaultValues = useMemo(() => {
    if (multipleReceivers && multipleReceivers.length) {
      return multipleReceivers?.map(receiver => ({
        couponCode: receiver?.couponCode || '',
        pricingTier: receiver?.pricingTier || '',
        insuranceType: receiver?.insuranceType || '',
        shipmentCharge: receiver?.shipmentCharge || 0,
        insuranceCharge: receiver?.insuranceCharge || 0
      }));
    } else {
      return [
        {
          couponCode: '',
          pricingTier: '',
          insuranceType: '',
          shipmentCharge: 0,
          insuranceCharge: 0
        }
      ];
    }
  }, [multipleReceivers]);

  const [coupons, setCoupons] = useRecoilState(
    multipleShipmentCounponListState
  );
  const [insurancePrompts, setInsurancePrompts] = useState([
    { index: 0, isInsurancePrompt: true }
  ]);
  const [couponIndex, setCouponIndex] = useState(0);
  const [initialValues, setInitialValues] = useState(defaultValues);

  const onCompleted = data => {
    sendAlert('Coupon code applied', 'success');
    const newCoupons = [...coupons];
    newCoupons[couponIndex] = { index: couponIndex, data: data?.getCoupon };
    setCoupons(newCoupons);
    setCouponIndex(0);
  };

  const [getCouponValue, couponResult] = useGetCouponQuery(onCompleted);
  const [pickupRates] = useRecoilState(savePickupRateState);

  const getMultipleShipmentRates = useGetShipmentRateQueryMultiple(
    multipleReceivers,
    setInitialValues
  );

  const currentIndex = multipleShipmentLinks.findIndex(
    link => history.location.pathname === link.link
  );

  const currentReceiver = useMemo(() => {
    return receiverSteps.find(step => step.isActive);
  }, [receiverSteps]);

  const activeReceiverIndex = useMemo(() => {
    return receiverSteps.findIndex(step => step === currentReceiver);
  }, [currentReceiver, receiverSteps]);

  const activeReceiver = multipleReceivers[activeReceiverIndex];
  const [getPickupRate, { loading: pickupLoading }] = useGetPickupRatesQuery(
    {}
  );

  const handlePickUpSelection = type => {
    setSelectedPickupPartner(type);
  };

  const previousReceiver = useCallback(() => {
    setReceiverSteps(receiver =>
      receiver.map((step, index) => ({
        ...step,
        isActive: index === activeReceiverIndex - 1
      }))
    );
  }, [activeReceiverIndex]);

  const saveAndProceedToNextReceiver = values => {
    const newMultipleReceivers = [...multipleReceivers];

    newMultipleReceivers.forEach((activeReceiver, i) => {
      const finalMultipleReceivers = {
        ...activeReceiver,
        ...values.deliveryOptions[i],
        couponCode: coupons[i] ? values.deliveryOptions[i]?.couponCode : '',
        budgetDeliveryCharge: selectedBudgetDelivery[i]?.budgetDeliveryCost,
        isCompleted: true
      };

      const isWithinLagos = senderDetail?.state?.toLowerCase() === 'lagos';
      const hasBudgetDelivery =
        values.deliveryOptions[i]?.pricingTier === 'LastMileBudget' &&
        !isWithinLagos;

      if (!hasBudgetDelivery) {
        delete finalMultipleReceivers.budgetDeliveryCharge;
      }

      newMultipleReceivers[i] = { ...finalMultipleReceivers };
    });

    setSaveShipmentDetail({
      ...saveShipmentDetail,
      ...(pickupOptionMatch
        ? {
            pickupCharge: toFixed(selectedPickupPartner?.pickupCharge),
            pickupPartner: selectedPickupPartner?.partner,
            partnerLogoUrl: selectedPickupPartner?.partnerLogoUrl,
            deliveryLocation: selectedPickupPartner?.deliveryLocation,
            ...(selectedPickupPartner?.pickupId && {
              pickupId: selectedPickupPartner?.pickupId
            })
          }
        : {}),
      multipleReceivers: newMultipleReceivers
    });
  };

  const {
    values: { deliveryOptions },
    errors,
    handleChange,
    setFieldValue,
    handleSubmit
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      deliveryOptions: initialValues
    },
    onSubmit: values => {
      saveAndProceedToNextReceiver(values);

      if (activeReceiverIndex === multipleReceivers.length - 1) {
        if (
          multipleReceivers?.some(
            receiver =>
              receiver?.receiverDetail?.countryCode !==
              saveShipmentDetail?.senderDetail?.countryCode
          )
        ) {
          setOpenInAppPrompt(true);
        } else {
          history.push(multipleShipmentLinks[currentIndex + 1].link);
        }
      }
    }
  });

  const removeCoupon = receiverIndex => {
    setCoupons(coupons =>
      coupons.map((coupon, couponIndex) =>
        couponIndex === receiverIndex ? null : coupon
      )
    );
    setFieldValue(`deliveryOptions.${receiverIndex}.couponCode`, '');
  };

  const handleRateSelection = async (rate, index) => {
    try {
      setFieldValue(`deliveryOptions.${index}`, {
        ...deliveryOptions[index],
        pricingTier: shipmentRateModeDictionary(rate?.pricingTier),
        shipmentCharge: toFixed(rate?.cost)
      });
      return true;
    } catch (error) {
      return error;
    }
  };

  const handleBudgetDelivery = (option, index) => {
    setSelectedBudgetDelivery(selectedBudgetDelivery => {
      return selectedBudgetDelivery.map((item, i) => {
        return { ...item, budgetDeliveryCost: option };
      });
    });
  };

  const handleCouponValidation = index => {
    const data = deliveryOptions?.[index]?.couponCode;
    if (data.length) {
      handleApplyCoupon(index);
    }
  };

  const handleInsuranceSelection = (type, index) => {
    const insuranceCharge =
      type?.value === insurancePlans[2].value
        ? multipleReceivers?.[index]?.items?.reduce(
            (sum, item) => sum + item.value,
            0
          ) * 0.05
        : type.amount * 100;

    setFieldValue(`deliveryOptions.${index}`, {
      ...deliveryOptions[index],
      insuranceCharge,
      insuranceType: type?.value
    });
  };

  const handleCouponChange = (couponCode, index) => {
    // const existingCode = deliveryOptions.find(
    //   option => option.couponCode === couponCode
    // );
    // if (!existingCode) {
    handleChange({
      target: {
        name: `deliveryOptions.${index}.couponCode`,
        value: couponCode
      }
    });
    // } else {
    //   setFieldError(
    //     `deliveryOptions.${index}.couponCode`,
    //     'Please use another coupon code'
    //   );
    // }
  };

  const handleApplyCoupon = index => {
    setCouponIndex(index);
    getCouponValue(deliveryOptions?.[index]?.couponCode);
  };

  const handleInsurancePrompt = index => {
    setInsurancePrompts(prompts =>
      prompts.map(prompt =>
        prompt.index === index
          ? { ...prompt, isInsurancePrompt: !prompt.isInsurancePrompt }
          : prompt
      )
    );
  };

  const selectedRate = useMemo(() => {
    return multipleShipmentRates[activeReceiverIndex]?.list?.find(
      rate =>
        deliveryOptions?.[activeReceiverIndex]?.pricingTier ===
        shipmentRateModeDictionary(rate?.pricingTier)
    );
  }, [multipleShipmentRates, activeReceiverIndex, deliveryOptions]);

  const totalItemWeight = useMemo(() => {
    if (!multipleReceivers) {
      return 0;
    }

    return (multipleReceivers || [])?.reduce((sum, receiver) => {
      let totaWeightValue = 0;

      receiver?.items?.forEach(item => {
        if (item.isVolumetric) {
          const volumetricWeight = Number(
            ((item?.width * item?.height * item?.length) / 5000).toFixed(2)
          );
          totaWeightValue += volumetricWeight;
        } else {
          totaWeightValue += item?.weight;
        }
      });

      return sum + totaWeightValue;
    }, 0);
  }, [multipleReceivers]);

  const pickupOptionMatch = useMemo(() => {
    if (!saveShipmentDetail || !shipmentMethods) {
      return false;
    }

    return isItemCollectionModeMatch(
      saveShipmentDetail
      // activeReceiverIndex
    );
  });

  // Pricing Tier (FedEx or DHL) for pickups outside Topship Hubs
  const pickupPricingTier = useMemo(() => {
    if (
      statesWithTopshipHubs.includes(saveShipmentDetail?.senderDetail?.state)
    ) {
      return '';
    }

    if (!selectedRate) {
      return '';
    }

    // [Express, FedEx, SaverPriority]
    if (
      ![
        shippingRates[0].value,
        shippingRates[7].value,
        shippingRates[11].value
      ].includes(selectedRate?.pricingTier)
    ) {
      return '';
    }

    if (selectedRate?.pricingTier === shippingRates[0].value) {
      return shippingRates[0].value;
    }

    return shippingRates[7].value;
  }, [saveShipmentDetail?.senderDetail, selectedRate]);

  // const isWithinLagos = senderDetail?.state?.toLowerCase() == 'lagos';
  // const itemCategories = multipleReceivers.flatMap(receiver =>
  //   receiver.items.map(item => item.category)
  // );

  const disableButton = useMemo(() => {
    // const activeReceiverData = deliveryOptions[activeReceiverIndex];
    const allItemsSelected = multipleReceivers.every((receiver, index) => {
      const receiverData = deliveryOptions[index];
      return (
        receiverData && receiverData.insuranceType && receiverData.pricingTier
      );
    });
    if (pickupOptionMatch && activeReceiverIndex === 0) {
      return !allItemsSelected || !selectedPickupPartner?.partner;
    }

    return !allItemsSelected;
  }, [
    activeReceiverIndex,
    deliveryOptions,
    multipleReceivers,
    pickupOptionMatch,
    selectedPickupPartner
  ]);

  const goBack = () => {
    if (activeReceiverIndex === 0 || multipleReceivers.length === 1) {
      history.push(multipleShipmentLinks[currentIndex].initialLink);
    } else {
      previousReceiver();
    }
  };

  const getExpressOption = multipleShipmentRates[
    activeReceiverIndex
  ]?.list?.find(rate => rate?.pricingTier === 'Express');

  const pickupPartners = useMemo(() => {
    if (!pickupRates) {
      return [];
    }

    return [...pickupRates];
  }, [pickupRates]);

  const pickupRequestData = useMemo(() => {
    const result = {
      senderDetail: {
        addressLine1: senderDetail?.addressLine1 || '',
        addressLine2: senderDetail?.addressLine2 || '',
        addressLine3: senderDetail?.addressLine3 || '',
        city: senderDetail?.city || '',
        country: senderDetail?.country || '',
        countryCode: senderDetail?.countryCode || '',
        postalCode: senderDetail?.postalCode || '',
        state: senderDetail?.state || ''
      },
      pickupDate: pickupDate,
      totalWeight: totalItemWeight
    };

    if (pickupPricingTier) {
      result.pricingTier = pickupPricingTier;
    }

    return result;
  }, [pickupPricingTier]);

  useEffect(() => {
    ReactGA.event({
      category: 'Shipment',
      action: 'User adds shipping items'
    });

    if (pickupOptionMatch) {
      getPickupRate({
        pickupDate,
        senderDetail: omit(senderDetail, [
          'id',
          'name',
          'email',
          'phoneNumber',
          'isCompleted',
          'isDefault',
          'updatedDate'
        ]),
        totalWeight: totalItemWeight
      });
    }

    getMultipleShipmentRates();

    if (pickupOptionMatch) {
      getPickupRate(pickupRequestData);
    }

    setInsurancePrompts(
      multipleReceivers.map((receiver, index) => {
        receiver?.items?.reduce(
          (sum, currentValue) => sum + currentValue?.value,
          0
        );
        return { index, isInsurancePrompt: true };
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pickupOptionMatch) {
      getPickupRate(pickupRequestData);
    }
  }, [pickupPricingTier]);

  useEffect(() => {
    if (pickupPricingTier && pickupOptionMatch) {
      const partner = pickupPartners?.find(
        partner => partner?.pricingTier === pickupPricingTier
      );

      if (partner) {
        if (selectedPickupPartner) {
          handlePickUpSelection(partner);
        }
      }
    }
  }, [pickupPartners]);

  useEffect(() => {
    if (history.location.state?.reValidate) {
      const isCompleted = checkIsCompleted(currentIndex, saveShipmentDetail);
      if (!isCompleted) {
        sendAlert('Please select your preferred delivery options', 'warning');
      }
    }
    history.replace({
      state: {}
    });
    // eslint-disable-next-line
  }, [history.location.state?.reValidate]);

  const isInsurancePrompt = insurancePrompts.find(
    prompt => prompt.index === activeReceiverIndex
  )?.isInsurancePrompt;
  const totalItemValue =
    activeReceiver?.items?.reduce((sum, item) => sum + item.value, 0) / 100;

  const canUseExtendedInsurance =
    !!totalItemValue && totalItemValue >= extendedInsuranceLimits.min;

  const selectedInsurance = insurancePlans.find(
    plan => plan.value === deliveryOptions?.[activeReceiverIndex]?.insuranceType
  );

  return (
    <>
      <NewShippingStepsLayout>
        <Helmet>
          <meta
            name='description'
            content='Topship Africa Shipment Request Delivery Options'
          />
          <title>Topship Africa Shipment Request Delivery Options</title>
          <link
            rel='canonical'
            href='https://topship.africa/shipment-request-delivery-options'
          />
        </Helmet>
        <form onSubmit={handleSubmit} className='delivery-options'>
          <div className='delivery-options__steps-container'>
            {/* <p className='mb-3'>
              Destination {activeReceiverIndex + 1}/{multipleReceivers.length}
            </p> */}
            <ReceiverStepsComponent
              receivers={multipleReceivers}
              activeReceiverIndex={activeReceiverIndex}
              receiverSteps={receiverSteps}
              setReceiverSteps={setReceiverSteps}
            />
          </div>

          <div className='delivery-options__items'>
            {multipleRateLoading ? (
              [...Array(2).keys()].map(key => (
                <div key={key} className='order-summary__shipment mobile-wrap'>
                  <div className='order-summary__shipment__wrap'>
                    <div className='order-summary__shipment__addresses'>
                      <div
                        className={`order-summary__shipment__address multiple`}
                      >
                        <div className='order-summary__shipment__address__wrap'>
                          <div className='order-summary__shipment__address__content w-100'>
                            <Skeleton className='w-100 h-100' />
                          </div>
                        </div>
                      </div>
                      <div className='order-summary__shipment__address deliver-to pb-0'>
                        <div className='order-summary__shipment__address__wrap'>
                          <div className='order-summary__shipment__address__content w-100'>
                            <Skeleton className='w-100 h-100' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className='order-summary__shipment mobile-wrap'>
                <div className='order-summary__shipment__wrap '>
                  <div className='order-summary__shipment__addresses'>
                    <div
                      className={`order-summary__shipment__address multiple`}
                    >
                      <div className='order-summary__shipment__address__wrap'>
                        <div className='order-summary__shipment__address__content'>
                          <h4 className='order-summary__shipment__address__name'>
                            PICKUP FROM
                          </h4>
                          <span className='order-summary__shipment__address__location multiple-delivery'>
                            <img
                              loading='lazy'
                              width='20'
                              height='14'
                              src={`https://flagcdn.com/w20/${saveShipmentDetail?.senderDetail.countryCode.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${saveShipmentDetail?.senderDetail.countryCode.toLowerCase()}.png 2x`}
                              alt=''
                            />
                            {`${saveShipmentDetail?.senderDetail.city} • ${saveShipmentDetail?.senderDetail.countryCode}`}
                          </span>
                        </div>
                        <div className='order-summary__shipment__address__action'>
                          <Button
                            onClick={() =>
                              history.push(multipleShipmentLinks[1].link)
                            }
                            variant='text'
                          >
                            <span>edit</span>
                            <Edit />
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className='order-summary__shipment__address  deliver-to  pb-0'>
                      <div className='order-summary__shipment__address__wrap'>
                        <div className='order-summary__shipment__address__content'>
                          <h4 className='order-summary__shipment__address__name'>
                            DELIVER TO
                          </h4>
                          <span className='order-summary__shipment__address__location multiple-delivery'>
                            <img
                              loading='lazy'
                              width='20'
                              height='14'
                              src={`https://flagcdn.com/w20/${activeReceiver?.receiverDetail?.countryCode.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${activeReceiver?.receiverDetail?.countryCode.toLowerCase()}.png 2x`}
                              alt=''
                            />
                            {`${activeReceiver?.receiverDetail?.city} • ${activeReceiver?.receiverDetail?.countryCode}`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='order-summary__shipment__meta mt-2'>
                    <div
                      className={`mb-2 toggle-container ${
                        !openToggle[activeReceiverIndex].delivery
                          ? 'border-shadow'
                          : ''
                      }`}
                      onClick={() =>
                        setOpenToggle(param => ({
                          ...param,
                          delivery: !param.delivery
                        }))
                      }
                    >
                      <h4 className=' mb-0'>Choose a Shipping Option</h4>
                      <img
                        className={`arrow-icon ${
                          openToggle[activeReceiverIndex].delivery
                            ? 'upside-down'
                            : ''
                        }`}
                        src={SelectArrowBlue}
                        alt=''
                      />
                    </div>
                    {openToggle[activeReceiverIndex].delivery && (
                      <DeliveryOptions1
                        showLabel={false}
                        loading={multipleRateLoading}
                        rates={multipleShipmentRates[activeReceiverIndex]?.list}
                        selectedRate={selectedRate}
                        isMultiple
                        activeIndex={activeReceiverIndex}
                        handleRateSelection={handleRateSelection}
                        handleBudgetDelivery={handleBudgetDelivery}
                        selectedBudgetDelivery={
                          selectedBudgetDelivery[activeReceiverIndex]
                            ?.budgetDeliveryCost
                        }
                        country={
                          multipleReceivers[activeReceiverIndex]?.receiverDetail
                            ?.country
                        }
                        shipmentDetail={saveShipmentDetail}
                        multipleReceiver={
                          multipleReceivers[activeReceiverIndex]
                        }
                        openSaver={() => setOpenSaverAlert(true)}
                      />
                    )}
                  </div>
                  {pickupOptionMatch ? (
                    <div className='mt-2 order-summary__shipment__meta'>
                      <div
                        className={`mb-2 toggle-container ${
                          !openToggle[activeReceiverIndex].pickup
                            ? 'border-shadow'
                            : ''
                        }`}
                        onClick={() =>
                          setOpenToggle(param => ({
                            ...param,
                            pickup: !param.pickup
                          }))
                        }
                      >
                        <label className='delivery-options__label mb-0'>
                          Choose Pick up Partner
                        </label>
                        <img
                          className={`arrow-icon ${
                            openToggle[activeReceiverIndex].pickup
                              ? 'upside-down'
                              : ''
                          }`}
                          src={SelectArrowBlue}
                          alt=''
                        />
                      </div>
                      {openToggle[activeReceiverIndex].pickup && (
                        <div className='delivery-options__items'>
                          {pickupLoading
                            ? [...Array(2).keys()].map(key => (
                                <div
                                  key={key}
                                  className={`delivery-options__item`}
                                >
                                  <div className='delivery-options__item__wrap discount'>
                                    <div className='delivery-options__item__content w-100'>
                                      <div className='delivery-options__item__name w-100'>
                                        <Skeleton className='w-100' />
                                      </div>
                                      <div className='delivery-options__item__address w-100'>
                                        <Skeleton className='w-100' />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : pickupPartners?.map((pickup, index) => {
                                const isBadImage = pickupPartnersWithBadImages.includes(
                                  pickup?.partner
                                );

                                const isFez =
                                  pickup?.partner ===
                                  pickupPartnersWithBadImages[1];

                                return (
                                  <div
                                    key={index}
                                    onClick={() =>
                                      handlePickUpSelection(pickup)
                                    }
                                    style={{
                                      background: lightColors[index % 5]
                                    }}
                                    className={`delivery-options__item multiple-delivery${
                                      selectedPickupPartner.partner ===
                                      pickup.partner
                                        ? ' active'
                                        : ''
                                    }`}
                                  >
                                    <div
                                      className={`delivery-options__item__radio`}
                                    >
                                      <Radio
                                        checked={
                                          selectedPickupPartner.partner ===
                                          pickup.partner
                                        }
                                        sx={{ transform: 'scale(1.5)' }}
                                      />
                                    </div>
                                    <div className='delivery-options__item__wrap'>
                                      <div className='delivery-options__item__content'>
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            ...(isBadImage
                                              ? {
                                                  marginLeft: isFez
                                                    ? '20px'
                                                    : '15px'
                                                }
                                              : {})
                                          }}
                                          className='delivery-options__item__name mb-1'
                                        >
                                          <img
                                            style={{
                                              height: '26px',
                                              objectFit: 'contain',
                                              ...(isBadImage
                                                ? {
                                                    transform: `scale(${
                                                      isFez ? 3 : 2
                                                    })`
                                                  }
                                                : {})
                                            }}
                                            src={
                                              pickup?.partnerLogoUrl ||
                                              'https://upload.wikimedia.org/wikipedia/commons/c/ce/Example_image.png'
                                            }
                                            alt={pickup?.partner}
                                            data-default-src='https://upload.wikimedia.org/wikipedia/commons/c/ce/Example_image.png'
                                          ></img>
                                          <h3
                                            className='mb-0'
                                            style={{
                                              paddingLeft: isFez
                                                ? '35px'
                                                : isBadImage
                                                ? '25px'
                                                : '5px'
                                            }}
                                          >
                                            {pickup.partner}
                                          </h3>
                                        </div>
                                        <div className='delivery-options__item__address'>
                                          <span
                                            style={{
                                              ...(isFez
                                                ? {
                                                    position: 'relative',
                                                    zIndex: '9999999'
                                                  }
                                                : {})
                                            }}
                                          >
                                            {pickup?.duration ||
                                              getPlan('pickup', pickup?.partner)
                                                ?.pickupDeliveryTime}
                                          </span>
                                        </div>
                                      </div>
                                      <div className='delivery-options__item__price'>
                                        <span>
                                          {toCurrency(
                                            activeReceiverIndex === 0
                                              ? pickup?.pickupCharge / 100
                                              : 0
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          {!pickupRates.length && !pickupLoading ? (
                            <EmptyList
                              emptyIcon={emptyStateIcon}
                              heading='No Available pickup partner'
                            />
                          ) : null}
                        </div>
                      )}
                    </div>
                  ) : null}

                  <div className='order-summary__shipment__meta mt-2'>
                    <div
                      className={`mb-2 toggle-container ${
                        !openToggle[activeReceiverIndex].insurance
                          ? 'border-shadow'
                          : ''
                      }`}
                      onClick={() =>
                        setOpenToggle(param => ({
                          ...param,
                          insurance: !param.insurance
                        }))
                      }
                    >
                      <h4 className='mb-0'>Choose an Insurance Option</h4>
                      <img
                        className={`arrow-icon ${
                          openToggle[activeReceiverIndex].insurance
                            ? 'upside-down'
                            : ''
                        }`}
                        src={SelectArrowBlue}
                        alt=''
                      />
                    </div>
                    {openToggle[activeReceiverIndex].insurance && (
                      <>
                        {isInsurancePrompt && (
                          <div className='mb-2'>
                            <Alert
                              severity={'info'}
                              sx={{
                                fontSize: '1.5rem',
                                fontFamily: 'General Sans',
                                backgroundColor: '#e6f2fe',
                                color: '#22428F',
                                border: 'none',
                                '& .MuiAlert-icon': {
                                  color: '#22428F'
                                }
                              }}
                              onClose={() =>
                                handleInsurancePrompt(activeReceiverIndex)
                              }
                            >
                              We recommend purchasing Premium
                              {totalItemValue >= extendedInsuranceLimits.min
                                ? '/Extended'
                                : ''}{' '}
                              Insurance to protect your goods in transit.
                            </Alert>
                          </div>
                        )}
                        <div
                          className={`order-summary__shipment__meta__wrap multiple-delivery`}
                        >
                          {insurancePlans
                            .filter(
                              (_, index) =>
                                canUseExtendedInsurance ||
                                index < insurancePlans.length - 1
                            )
                            .map((plan, index) => (
                              <div
                                onClick={() =>
                                  handleInsuranceSelection(
                                    plan,
                                    activeReceiverIndex
                                  )
                                }
                                style={{
                                  background: lightColors[index % 5]
                                }}
                                key={plan.value}
                                className={`delivery-options__item multiple-delivery${
                                  deliveryOptions?.[activeReceiverIndex]
                                    ?.insuranceType === plan.value
                                    ? ' active'
                                    : ''
                                }`}
                              >
                                <div
                                  className={`delivery-options__item__radio`}
                                >
                                  <Radio
                                    checked={
                                      deliveryOptions?.[activeReceiverIndex]
                                        ?.insuranceType === plan.value
                                    }
                                    sx={{ transform: 'scale(1.5)' }}
                                  />
                                </div>
                                <div className='delivery-options__item__wrap'>
                                  <div className='delivery-options__item__content'>
                                    <div className='delivery-options__item__name'>
                                      <h3>{plan.name}</h3>
                                    </div>
                                    <div className='delivery-options__item__address'>
                                      <span>{plan.summary}</span>
                                    </div>
                                  </div>
                                  <div className='delivery-options__item__price'>
                                    <span>
                                      {plan?.price
                                        ? toCurrency(
                                            plan?.price(totalItemValue)
                                          )
                                        : plan.amount
                                        ? toCurrency(plan.amount)
                                        : plan.cost}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </>
                    )}
                  </div>

                  <div className='order__summary__shipment__meta'>
                    <div
                      style={{ border: '0', boxShadow: 'none' }}
                      className='order-summary__coupon'
                    >
                      <div className='order-summary__coupon__wrap p-0'>
                        {couponResult.loading &&
                        couponIndex === activeReceiverIndex ? (
                          <>
                            <h4 className='mt-1 mb-1'>Applying...</h4>
                            <div className={`delivery-options__item`}>
                              <div className='delivery-options__item__wrap discount'>
                                <div className='delivery-options__item__content w-100'>
                                  <div className='delivery-options__item__address w-100'>
                                    <Skeleton className='w-100' />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : coupons[activeReceiverIndex] ? (
                          <>
                            <div className='mt-1 mb-2 d-flex justify-content-between align-items-center'>
                              <h4 className='mt-2'>Coupon code applied</h4>
                              <div className='item-description__main__action'>
                                <Button
                                  variant='text'
                                  onClick={() =>
                                    removeCoupon(activeReceiverIndex)
                                  }
                                >
                                  <span>Erase coupon</span>
                                  <RemoveCircle />
                                </Button>
                              </div>
                            </div>
                            <div className={`delivery-options__item active`}>
                              <div className='delivery-options__item__wrap discount'>
                                <div className='delivery-options__item__content'>
                                  <div
                                    style={{
                                      textDecoration: 'line-through'
                                    }}
                                    className='delivery-options__item__address'
                                  >
                                    <span>
                                      {toCurrency(
                                        calculateTotalCost(
                                          toFixed(
                                            (selectedRate?.cost +
                                              (selectedBudgetDelivery[
                                                activeReceiverIndex
                                              ]?.budgetDeliveryCost
                                                ? selectedBudgetDelivery[
                                                    activeReceiverIndex
                                                  ]?.budgetDeliveryCost
                                                : 0)) /
                                              100
                                          ),
                                          pickupOptionMatch &&
                                            activeReceiverIndex === 0
                                            ? Number(
                                                selectedPickupPartner?.pickupCharge /
                                                  100
                                              )
                                            : 0,
                                          selectedInsurance?.amount
                                        )
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className='delivery-options__item__price discount'>
                                  <span>
                                    {toCurrency(
                                      applyCouponToPrice(
                                        calculateTotalCost(
                                          toFixed(
                                            (selectedRate?.cost +
                                              (selectedBudgetDelivery[
                                                activeReceiverIndex
                                              ]?.budgetDeliveryCost
                                                ? selectedBudgetDelivery[
                                                    activeReceiverIndex
                                                  ]?.budgetDeliveryCost
                                                : 0)) /
                                              100
                                          ),
                                          pickupOptionMatch &&
                                            activeReceiverIndex === 0
                                            ? Number(
                                                selectedPickupPartner?.pickupCharge /
                                                  100
                                              )
                                            : 0,
                                          selectedInsurance?.amount
                                        ),
                                        coupons[activeReceiverIndex]?.data
                                          ? coupons[activeReceiverIndex]?.data
                                              ?.value
                                          : 0,
                                        coupons[activeReceiverIndex]?.data
                                          ? coupons[activeReceiverIndex]?.data
                                              ?.type
                                          : 'Value'
                                      )
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <Input
                            variant='standard'
                            customLabel='Coupon Code'
                            type='text'
                            id='code'
                            name='code'
                            onChange={event =>
                              handleCouponChange(
                                event.target.value,
                                activeReceiverIndex
                              )
                            }
                            className='inputGroup2__inputs__input'
                            placeholder='Got a code?'
                            value={
                              deliveryOptions?.[activeReceiverIndex]?.couponCode
                            }
                            error={Boolean(
                              errors?.deliveryOptions?.[activeReceiverIndex]
                                ?.couponCode
                            )}
                            helperText={
                              errors?.deliveryOptions?.[activeReceiverIndex]
                                ?.couponCode
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <div className='order-summary__shipment__address__action'>
                                    <Button
                                      disabled={
                                        !selectedInsurance || !selectedRate
                                      }
                                      onClick={() =>
                                        handleCouponValidation(
                                          activeReceiverIndex
                                        )
                                      }
                                      variant='text'
                                    >
                                      <span>Apply</span>
                                    </Button>
                                  </div>
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='delivery-options__footer'>
            <div onClick={goBack} className='delivery-options__footer__action'>
              Go Back
            </div>
            <SubmitButton text={'Continue'} disabled={disableButton} />
          </div>
        </form>
      </NewShippingStepsLayout>
      <TermsAndConditionsPrompt
        openPrompt={openInAppPrompt}
        setOpenPrompt={setOpenInAppPrompt}
        openOrderSummary={() =>
          history.push(multipleShipmentLinks[currentIndex + 1].link)
        }
      />
      <InAppSaverPrompt
        openPrompt={openSaverAlert}
        setOpenPrompt={setOpenSaverAlert}
        selectNewService={() => {
          setFieldValue(`deliveryOptions.${activeReceiverIndex}`, {
            ...deliveryOptions[activeReceiverIndex],
            pricingTier: shipmentRateModeDictionary(
              getExpressOption?.pricingTier
            ),
            shipmentCharge: toFixed(getExpressOption?.cost)
          });
          // sets(getExpressOption || {});
          setOpenSaverAlert(false);
        }}
      />
    </>
  );
};

export default MultipleDeliveryOptions;
