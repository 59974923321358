import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  chinaImportState,
  filteredShopNShipLinksState,
  saveShopNShipRateState,
  selectedRateState,
  shopNShipDetailsState,
  shopnshipSelectedCountryState,
  shopnshipSelectedProductState,
  selectedLastMileDeliveryOption,
  saveLastMileState
} from '../../../../../recoil/atoms';
import { toFixed } from '../../../../../utilities/to-fixed';
import shipmentRateModeDictionary from '../../../../../constants/shipment-rate-modes-dictionary';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Grid, Skeleton } from '@mui/material';
import NewShopNShipStepsLayout from '../../../../../components/Layouts/shop-n-ship-steps-layout';
import SubmitButton from '../../../../../components/submit-button';
import testJSON from '../../../../../utilities/test-json';
import AddressGuidePrompt from '../../../../../components/modals/address-guide-prompt';
import {
  useGetLastMileRatesQuery,
  useGetShopNShipRateQuery,
  useGetItemCategoriesQuery,
  useGetShopnshipCurrencyRates
} from '../../../../../operations/queries';
import insurancePlans from '../../../../../constants/insurance-plans';

import {
  WhiteCheck,
  SelectArrowBlue
} from '../../../../../constants/asset-contants';
import BusyOverlay from '../../../../../components/busy-overlay';
import emptyStateIcon from '../../../../../assets/svg/empty.svg';
import {
  DeliveryOptions1,
  DeliveryOptions2,
  InsuranceOptions
} from '../../../../../components/shipping-options';
import { GetShopnshipHubAddress } from '../../../../../constants/shopnship-hub-address';
import Input from '../../../../../components/new-dashboard/custom-input';
import {
  getCountryTags,
  shippingFromOptions,
  isStandard
} from '../../../../../constants/shopnship-constants';
import { useSendAlert } from '../../../../../utilities/send-alert';
import { PackageNameCustomLabelMessage } from '../../../../../constants/shopnship-custom-label-message';
import HowItWorks from '../../../../../components/how-it-works';
import HowItWorksTrigger from '../../../../../components/how-it-works-trigger';

const countryTags = getCountryTags();

const expressLabel = 'TOPSHIP AFRICA/LOS 80205';

const packageRegex = (shippingFrom = '') => {
  if (!shippingFrom) {
    return null;
  }

  const option = shippingFromOptions.find(
    option => option.value === shippingFrom
  );

  if (!option || !option.origin) {
    return null;
  }

  return new RegExp(option.origin, 'g');
};

const expressValidation = yup.string().required('Package Name is required');

const ShopnshipShippingInformation = () => {
  const [shopNshipDetails, setSaveShopNshipDetails] = useRecoilState(
    shopNShipDetailsState
  );

  const history = useHistory();

  const sendAlert = useSendAlert();

  const [isChinaImport] = useRecoilState(chinaImportState);

  const isArray = testJSON(shopNshipDetails?.itemDescription);

  // const [getShopNshipRates, { loading }] = useGetShopNShipRateQuery({});

  // const [getShopNshipRates, { loading }] = useGetShopNShipRateQuery({
  //   onCompleted: data => {
  //     if (data?.getShopnshipRates?.length === 1) {
  //       handleRateSelection(data?.getShopnshipRates?.[0]);
  //     }
  //   }
  // });

  const [getShopnshipRates] = useGetShopnshipCurrencyRates();

  const [showAddressGuide, setShowAddressGuide] = useState(false);

  const [shopNShipRates] = useRecoilState(saveShopNShipRateState);

  const [lastMileRates] = useRecoilState(saveLastMileState);

  const [isChecked, setIsChecked] = useState(false);

  const [openToggle, setOpenToggle] = useState({
    delivery: true,
    insurance: true,
    lastMile: true
  });

  const [selectedRate, setSelectedRate] = useRecoilState(selectedRateState);

  const [selectedCountry] = useRecoilState(shopnshipSelectedCountryState);

  const [selectedLastMile, setSelectedLastMile] = useRecoilState(
    selectedLastMileDeliveryOption
  );

  const [openHowItWorks, setOpenHowItWorks] = useState(false);

  const [selectedProduct] = useRecoilState(shopnshipSelectedProductState);

  const activeRate = shopNShipRates.filter(
    rate => rate.pricingTier === shopNshipDetails?.pricingTier
  );

  const handleInsuranceSelection = plan => {
    const insuranceCharge =
      plan.value === insurancePlans[2].value
        ? totalItemValue * 0.05
        : plan.amount;

    setFieldValue('insuranceType', plan.value);
    setFieldValue('insuranceCharge', insuranceCharge);
  };

  const handleRateSelection = rate => {
    setSelectedRate(rate);

    setFieldValue('pricingTier', rate?.pricingTier);

    if (rate?.pricingTier === 'Express') {
      setFieldValue('packageName', expressLabel);
    } else {
      setFieldValue('packageName', '');
    }
  };

  const [getItemsCategories] = useGetItemCategoriesQuery();

  const [getShopNshipRates, { loading }] = useGetShopNShipRateQuery({
    onCompleted: data => {
      if (data?.getShopnshipRates?.length === 1) {
        handleRateSelection(data?.getShopnshipRates?.[0]);
      }
    }
  });

  const [
    getLastMileRate,
    { data: lastMileData, loading: lastMileLoading }
  ] = useGetLastMileRatesQuery({ fetchPolicy: 'no-cache' });

  const [filteredLinks] = useRecoilState(filteredShopNShipLinksState);

  const handleLastMileSelection = rate => {
    setSelectedLastMile(rate);
  };

  const totalItemValue =
    (isArray
      ? JSON.parse(shopNshipDetails?.itemDescription)?.reduce(
          (sum, item) => sum + item?.amountDue,
          0
        )
      : shopNshipDetails?.amountDue) || 0;

  const isExpressOption = useMemo(() => {
    if (!selectedRate) {
      return false;
    }

    return selectedRate?.pricingTier === 'Express';
  }, [selectedRate]);

  const onCompleted = data => {
    const items = JSON.parse(shopNshipDetails?.itemDescription);

    // we are joining all tracking numbers for backward compatibility (old shopnship did not have multiple items)
    const trackingNumber = isArray
      ? items?.map(item => item?.trackingNumber)?.join()
      : shopNshipDetails?.trackingNumber;

    // we are joining all proofs of id for backward compatibility (old shopnship did not have multiple items)
    const proofOfId = isArray
      ? items.map(item => item?.proofOfId)?.join()
      : shopNshipDetails?.proofOfId;

    // we are joining all evidences of purchase for backward compatibility (old shopnship did not have multiple items)
    const evidenceOfPurchase = isArray
      ? items.map(item => item?.evidenceOfPurchase)?.join()
      : shopNshipDetails?.evidenceOfPurchase;

    setSaveShopNshipDetails({
      ...shopNshipDetails,
      ...values,
      proofOfId,
      trackingNumber,
      evidenceOfPurchase,
      ...(isImportBooking
        ? {
            lastMileCharge: toFixed(selectedLastMile?.cost),
            lastMilePricingTier: shipmentRateModeDictionary(
              selectedLastMile?.pricingTier
            )
          }
        : {}),
      ...(!isChinaImport && {
        initialCharge: selectedRate?.cost,
        foreignExchangeCost: selectedRate?.foreignExchangeCost,
        foreignExchangeRate: selectedRate?.foreignExchangeRate
      })
    });

    history.push(filteredLinks[currentIndex + 1].link);
  };

  const tagName = useMemo(() => {
    if (isStandard(selectedCountry?.value)) {
      return selectedCountry?.origin;
    }

    return countryTags[shopNshipDetails?.shippingFrom?.split(' ')[0]] || '';
  }, [selectedCountry, shopNshipDetails?.shippingFrom]);

  const regex = useMemo(() => {
    if (!shopNshipDetails?.shippingFrom) {
      return null;
    }

    return packageRegex(shopNshipDetails?.shippingFrom);
  }, [shopNshipDetails?.shippingFrom]);

  const standardValidation = useMemo(() => {
    if (!regex) {
      return yup.string().required('Package Name is required');
    }

    return yup
      .string()
      .required('Package Name is required')
      .matches(regex, {
        message: `Please include "- ${
          countryTags[shopNshipDetails?.shippingFrom?.split(' ')[0]]
        }" `
      });
  }, [regex, shopNshipDetails]);

  const isImportBooking = useMemo(() => {
    if (!shopNshipDetails) {
      return false;
    }

    return (
      !shopNshipDetails?.id &&
      shopNshipDetails?.deliveryState &&
      shopNshipDetails?.deliveryState?.toLowerCase() !== 'lagos'
    );
  }, [shopNshipDetails]);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      packageName: isExpressOption ? expressValidation : standardValidation,
      pricingTier: yup.string().required('Please select an option')
    });
  }, [isExpressOption, standardValidation]);

  const currentIndex = useMemo(() => {
    if (!history.location.pathname) {
      return -1;
    }

    return filteredLinks.findIndex(
      link => link.link === history.location.pathname
    );
  }, [filteredLinks, history.location.pathname]);

  const {
    values,
    touched,
    setFieldValue,
    errors,
    handleChange,
    handleSubmit
  } = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      // pricingTier: '',
      packageName: '',
      isChinaImport: isChinaImport,
      insuranceType: '',
      insuranceCharge: 0
      // uploadingMyDocuments: true,
      // receiverPaymentDetails: true
    },

    onSubmit: values => {
      setSaveShopNshipDetails({ ...shopNshipDetails, ...values });

      if (!values.insuranceType || !selectedRate) {
        sendAlert('Please select your Insurance Type', 'error');
        window.scroll(0, 0);
      } else {
        delete values.isChinaImport;

        onCompleted();
      }
    }
  });

  useEffect(() => {
    if (shopNShipRates) {
      const activeRates = shopNShipRates.filter(
        rate => rate.pricingTier === shopNshipDetails?.pricingTier
      );
      setSelectedRate(activeRates[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopNShipRates]);

  useEffect(() => {
    getItemsCategories({
      pricingTier: selectedRate?.pricingTier || 'Standard',
      shippingFrom: selectedCountry?.value
    });
  }, [selectedRate]);

  useEffect(() => {
    if (shopNshipDetails?.pricingTier) {
      setFieldValue('pricingTier', shopNshipDetails.pricingTier);

      setSelectedRate({ pricingTier: shopNshipDetails.pricingTier });
    }

    if (shopNshipDetails?.packageName) {
      setFieldValue('packageName', shopNshipDetails.packageName);
    }

    // eslint-disable-next-line
  }, [selectedCountry?.value, shopNshipDetails]);

  useEffect(() => {
    if (shopNshipDetails) {
      const items = JSON.parse(shopNshipDetails?.itemDescription);

      const requestRatesData = items?.map(item => {
        return { weight: item?.estimatedWeight, category: item?.category };
      });

      if (!isChinaImport) {
        getShopNshipRates({
          items: requestRatesData,
          shippingFrom: shopNshipDetails?.shippingFrom
        });
      } else {
        getShopNshipRates({ isChinaImport: true, items: requestRatesData });
      }

      let totalValue = 0;

      items?.forEach(item => {
        if (item.isVolumetric) {
          const volumetricWeight = Number(
            ((item.width * item.height * item.length) / 5000).toFixed(2)
          );
          totalValue += volumetricWeight;
        } else {
          totalValue += item.weight || item.estimatedWeight;
        }
      });

      if (isImportBooking) {
        getLastMileRate({
          receiverDetail: {
            name: shopNshipDetails?.deliveryName,
            email: shopNshipDetails?.email,
            phoneNumber: shopNshipDetails?.phoneNumber,
            addressLine1: shopNshipDetails?.deliveryAddress,
            city: shopNshipDetails?.deliveryCity || '',
            country: 'Nigeria',
            countryCode: 'NG',
            state: shopNshipDetails?.deliveryState || 'Lagos'
          },
          totalWeight: parseFloat(totalValue, 10)
        });
      }

      if (!!shopNshipDetails?.id) {
        setFieldValue('insuranceType', shopNshipDetails.insuranceType);
        setFieldValue(
          'insuranceCharge',
          shopNshipDetails.insuranceCharge / 100
        );
        setIsChecked(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopNshipDetails, isChinaImport, isImportBooking]);

  useEffect(() => {
    if (isChinaImport) {
      if (selectedRate?.cost) {
        const params = {
          shippingFrom: 'CHINA',
          chinaCost: selectedRate?.cost
        };

        getShopnshipRates(params);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRate?.cost, shopNshipDetails?.shippingFrom, isChinaImport]);

  useEffect(() => {
    if (!lastMileLoading && lastMileData) {
      const defaultOption = lastMileData?.getLastMileRates?.[0];
      if (defaultOption) {
        setSelectedLastMile(defaultOption);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMileLoading, lastMileData, selectedLastMile]);

  return (
    <>
      <NewShopNShipStepsLayout mobileTitle='Shipping Information'>
        {openHowItWorks ? (
          <HowItWorks
            openHowItWorks={openHowItWorks}
            setOpenHowItWorks={setOpenHowItWorks}
          />
        ) : null}
        <div className='new-shop-n-ship__modal__body'>
          {isChinaImport ? null : (
            <HowItWorksTrigger setOpenHowItWorks={setOpenHowItWorks} />
          )}

          <div className='new-shop-n-ship__modal__body__wrap'>
            <div className='new-shop-n-ship__modal__body__content'>
              <form
                onSubmit={handleSubmit}
                className='delivery-options new-shop-n-ship__modal__body__content__welcome-guide__shipping-guide'
              >
                <div className='delivery-options mt-5'>
                  <div className='mb-2'>
                    <div
                      onClick={() =>
                        setOpenToggle(param => ({
                          ...param,
                          delivery: !param.delivery
                        }))
                      }
                      className={`mb-2 toggle-container ${
                        !openToggle.delivery ? 'border-shadow' : ''
                      }`}
                    >
                      <label className='delivery-options__label mb-0'>
                        Choose a Shipping Option
                      </label>
                      <img
                        className={`arrow-icon ${
                          openToggle.delivery ? 'upside-down' : ''
                        }`}
                        src={SelectArrowBlue}
                        alt=''
                      />
                    </div>
                    {openToggle.delivery && (
                      <DeliveryOptions2
                        loading={loading}
                        shopNShipRates={shopNShipRates}
                        // shopNShipRates={
                        //   isChinaImport ? shopNShipRates : activeRate
                        // }
                        selectedRate={selectedRate}
                        handleRateSelection={handleRateSelection}
                        // handleRateSelection={
                        //   isChinaImport ? handleRateSelection : null
                        // }
                      />
                    )}
                  </div>
                  {isImportBooking ? (
                    <div className='mb-2'>
                      <div
                        className={`mb-2 toggle-container ${
                          !openToggle.lastMile ? 'border-shadow' : ''
                        }`}
                        onClick={() =>
                          setOpenToggle(param => ({
                            ...param,
                            lastMile: !param.lastMile
                          }))
                        }
                      >
                        <label className='delivery-options__label mb-0'>
                          Choose a Delivery Partner
                        </label>
                        <img
                          className={`arrow-icon ${
                            openToggle.lastMile ? 'upside-down' : ''
                          }`}
                          src={SelectArrowBlue}
                          alt=''
                        />
                      </div>
                      {openToggle.lastMile && (
                        <>
                          <p
                            style={{
                              fontSize: '16px',
                              lineHeight: '24px',
                              color: '#545859',
                              fontWeight: 500
                            }}
                          >
                            Your delivery location is out of{' '}
                            <b>Lagos, Nigeria.</b> Please select a delivery
                            method to get it delivered to you
                          </p>

                          <DeliveryOptions1
                            showLabel={false}
                            loading={lastMileLoading}
                            rates={lastMileRates}
                            selectedRate={selectedLastMile}
                            handleRateSelection={handleLastMileSelection}
                            isLastMile={isImportBooking}
                            shipmentDetail={shopNShipRates}
                          />
                        </>
                      )}
                    </div>
                  ) : null}
                  <div className='mb-2'>
                    <div
                      className={`mb-2 toggle-container ${
                        !openToggle.insurance ? 'border-shadow' : ''
                      }`}
                      onClick={() =>
                        setOpenToggle(param => ({
                          ...param,
                          insurance: !param.insurance
                        }))
                      }
                    >
                      <label className='delivery-options__label'>
                        Choose an Insurance Option
                      </label>
                      <img
                        className={`arrow-icon ${
                          openToggle.insurance ? 'upside-down' : ''
                        }`}
                        src={SelectArrowBlue}
                        alt=''
                      />
                    </div>
                    {openToggle.insurance && (
                      <InsuranceOptions
                        insurancePlans={insurancePlans}
                        totalItemValue={totalItemValue}
                        values={values}
                        handleInsuranceSelection={handleInsuranceSelection}
                        shopNshipDetails={shopNshipDetails}
                      />
                    )}
                  </div>
                </div>

                <Grid container rowSpacing='16px' columnSpacing='16px'>
                  <Input
                    name='packageName'
                    value={values.packageName}
                    error={touched.packageName && Boolean(errors.packageName)}
                    helperText={touched.packageName && errors.packageName}
                    customLabelMessage={
                      <PackageNameCustomLabelMessage
                        tagName={tagName}
                        isExpress={isExpressOption}
                      />
                    }
                    onChange={handleChange}
                    customLabel='Name on package'
                    type='text'
                    placeholder={
                      isChinaImport
                        ? '邓涛topship - Jane Doe'
                        : isExpressOption
                        ? expressLabel
                        : `e.g: Garrick Braide - ${
                            countryTags[
                              isStandard(selectedCountry?.value)
                                ? selectedCountry?.value
                                : shopNshipDetails?.shippingFrom?.split(' ')[0]
                            ] || ''
                          }`
                    }
                    containerClassName='new-shop-n-ship__modal__body__form__item__input'
                    fullWidth
                    disabled={isExpressOption || !selectedRate}
                  />
                </Grid>

                <div>
                  <div className=''>
                    <label>Delivery address</label>
                    {values.packageName &&
                    values.packageName.includes(
                      isExpressOption ? expressLabel : tagName
                    ) ? (
                      <div>
                        <div className='address-info-wrap mb-2'>
                          {selectedCountry?.value && (
                            <>
                              <div className='address-info'>
                                <strong>
                                  {isStandard(selectedCountry.value)
                                    ? selectedCountry.value
                                    : selectedCountry?.label}{' '}
                                  Hub:{' '}
                                </strong>
                                {selectedCountry?.address ||
                                  GetShopnshipHubAddress(
                                    selectedCountry.value,
                                    selectedRate.pricingTier
                                  )}
                              </div>
                            </>
                          )}
                        </div>
                        <small>
                          Please input the above address as your shipping
                          address, your items get shipped there.
                        </small>
                      </div>
                    ) : (
                      // [...Array(1).keys()].map(key => (
                      <div className={`delivery-options__item`}>
                        <div className='delivery-options__item__wrap discount'>
                          <div className='delivery-options__item__content w-100'>
                            <div className='delivery-options__item__name w-100'>
                              <Skeleton className='w-100' />
                            </div>
                            <div className='delivery-options__item__address w-100'>
                              <Skeleton className='w-100' />
                            </div>
                          </div>
                        </div>
                      </div>
                      // ))
                    )}
                  </div>
                </div>

                <div className='delivery-options__footer'>
                  <div
                    onClick={() =>
                      !shopNshipDetails?.id &&
                      history.push(filteredLinks[currentIndex - 1].link)
                    }
                    className='delivery-options__footer__action'
                  >
                    Go Back
                  </div>
                  <SubmitButton text='Continue' />
                </div>
              </form>
            </div>
          </div>
        </div>
        <BusyOverlay loading={loading} speed='1.7s' />
      </NewShopNShipStepsLayout>
      <AddressGuidePrompt
        show={showAddressGuide}
        setShow={setShowAddressGuide}
      />
    </>
  );
};

export default ShopnshipShippingInformation;
