const roundUpSocials = {
  facebook: 'Facebook',
  twitter: 'Twitter',
  linkedin: 'LinkedIn',
  whatsapp: 'WhatsApp',
  instagram: 'Instagram',
  download: 'Download',
  hyperLink: 'HyperLink'
};

export default roundUpSocials;
