import { useState, useEffect } from 'react';
import { SurveyModalImg } from '../constants/asset-contants';
// import { CircularWarning } from '../constants/asset-constants';

const SurveyPromptModal = ({ shipments }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    function getCookie(name) {
      const cookies = document.cookie.split('; ');
      const cookie = cookies.find(row => row.startsWith(`${name}=`));
      return cookie ? cookie.split('=')[1] : null;
    }

    const hasSeenModal = getCookie('seenModal');

    if (!hasSeenModal && shipments?.length > 1) {
      setShowModal(true);
    }
  }, [shipments]);

  const handleCloseModal = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 20);
    document.cookie = `seenModal=true; path=/; expires=${date.toUTCString()}`;
    setShowModal(false);
  };

  return (
    <div
      className={`survey-modal ${showModal ? 'show-modal' : 'close-modal'} `}
      style={{
        transition: 'all 0.3s ease'
      }}
    >
      <a
        href='https://forms.gle/1SDMkywFbSCBhCJKA'
        target='_blank'
        onClick={() => handleCloseModal()}
      >
        <div className='survey-modal__img-container'>
          <img
            src={SurveyModalImg}
            alt='free-shipping-banner'
            className='survey-modal__img-container__image'
          />
        </div>
      </a>
      {/* <img
        src={CircularWarning}
        alt='close-icon'
        className='campaign-modal__close-icon'
        onClick={() => handleCloseModal()}
        /> */}
    </div>
  );
};

export default SurveyPromptModal;
