import React from 'react';
import roundUpSocials from '../../constants/roundup-socials';
import Whatsapp from '../../assets/svg/whatsapp2.svg';
import Facebook from '../../assets/image/facebook.svg';
import Twitter from '../../assets/svg/twitter2.svg';
import Hyperlink from '../../assets/svg/hyperlink.svg';
import Download2 from '../../assets/svg/download2.svg';
import { Close } from '@mui/icons-material';

const ShareButtons = ({ handleShareLink, closeSocials }) => {
  return (
    <div className='share-buttons'>
      <div className='share-groups'>
        <span onClick={() => handleShareLink(roundUpSocials.whatsapp)}>
          <img src={Whatsapp} alt='whatsapp' />
          <span>Whatsapp</span>
        </span>
        <span onClick={() => handleShareLink(roundUpSocials.twitter)}>
          <img src={Twitter} alt='twitter' />
          <span>Twitter</span>
        </span>
        <span onClick={() => handleShareLink(roundUpSocials.facebook)}>
          <img src={Facebook} alt='facebook' width={'50'} height={'50'} />
          <span>Facebook</span>
        </span>
      </div>
      <div className='share-groups '>
        <span onClick={() => handleShareLink(roundUpSocials.hyperLink)}>
          <img src={Hyperlink} alt='hyperlink' />
          <span>Copy Link</span>
        </span>
        <span onClick={() => handleShareLink(roundUpSocials.download)}>
          <img src={Download2} alt='download' />
          <span>Download</span>
        </span>
        <span onClick={closeSocials}>
          <Close sx={{ width: '40px', height: '40px' }} />
          <span>Close</span>
        </span>
      </div>
    </div>
  );
};

export default ShareButtons;
