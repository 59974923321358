import Jet from '../../assets/image/jet-3.png';
import TotalShipped from '../../assets/image/total-shipments.png';
import { useRef } from 'react';
import Close from '../../assets/svg/close.svg';
import Download from '../../assets/svg/download.svg';
import RoundupLogo from '../../assets/image/roundup-logo-small.png';
import { userRoundUpDataState } from '../../recoil/atoms';
import { useRecoilState } from 'recoil';
import ShareButtons from '../Commons/share-buttons';

const ShippedTotalRoundUpPrompt = ({
  openPrompt,
  setOpenPrompt,
  setOpenMainPrompt,
  downloadDisplay,
  setDownloadDisplay,
  getImage,
  handleShareLink,
  closeSocials
}) => {
  const [roundupData] = useRecoilState(userRoundUpDataState);
  const componentRef = useRef();
  const closeCurrentPrompt = () => {
    setOpenPrompt(false);
    setOpenMainPrompt(true);
    setDownloadDisplay(false);
  };

  return (
    <>
      <div className={`round-up__prompt fade${openPrompt ? ' show' : ''}`}>
        <div className='round-up__prompt__wrap'>
          <div className={`${downloadDisplay ? 'whiteBg' : ''}`}>
            <div>
              <div
                className={`total-shipped__body ${
                  downloadDisplay ? 'small' : ''
                }`}
              >
                <div className='padded-flex'>
                  <img
                    onClick={() => getImage(componentRef.current)}
                    style={{ cursor: 'pointer' }}
                    src={Download}
                    alt='download'
                  />
                  <img
                    onClick={closeCurrentPrompt}
                    style={{ cursor: 'pointer' }}
                    src={Close}
                    alt='close'
                  />
                </div>
                <div ref={componentRef} className='total-shipped__body__wrap'>
                  <div className='total-shipped__shipped-total'>
                    <div className='shipped-content'>
                      <div>
                        <img
                          src={RoundupLogo}
                          alt='round up logo'
                          className='roundup-logo-small'
                        />
                        <img
                          src={Jet}
                          alt='jet'
                          className={`absolute-jet1 ${
                            downloadDisplay ? 'tiny' : ''
                          }`}
                        />
                        <h5
                          className={`content-text-${
                            downloadDisplay ? 'small' : 'large'
                          }`}
                        >
                          You
                          <br />
                          made {roundupData?.numberOfShipments || 0} <br />{' '}
                          shipments
                          <br />
                          this year
                        </h5>
                      </div>
                    </div>
                    <div className='roundup-total-img'>
                      <img src={TotalShipped} alt='gift boxes' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {downloadDisplay && (
              <ShareButtons
                handleShareLink={handleShareLink}
                closeSocials={closeSocials}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={`round-up__prompt__backdrop fade${
          openPrompt ? ' show' : ''
        }`}
      ></div>
    </>
  );
};

export default ShippedTotalRoundUpPrompt;
