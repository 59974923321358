import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useRef } from 'react';
import { useRecoilState } from 'recoil';
import {
  bookImportTypes,
  shipmentDestinationTypes,
  shipmentMethods
} from '../constants/book-shipment-contants';
import { useGetUserLazyQuery } from '../operations/queries';
import {
  chinaImportState,
  filteredShopNShipLinksState,
  importOptionState,
  saveShipmentDetailsState,
  shipmentRequestState,
  warningState
} from '../recoil/atoms';
import BusyOverlay from './busy-overlay';
import BookShipmentContentItem from './Commons/book-shipment-content-item';
import { useAuthToken } from '../token';
import {
  seaFreightImportLinks,
  singleShipmentLinks
} from '../constants/shipment-links';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { shopNShipLinks } from '../constants/shopnship-links';

const BookImportOptions = ({ scroll, firstHeight, secondHeight }) => {
  const modal = useRef();
  const isMiniMobile = window.innerWidth <= 425;
  const [selectedImport, setSelectedImport] = useRecoilState(importOptionState);
  const [, getUserResult] = useGetUserLazyQuery(undefined, 'sender');
  const [, setRequestPage] = useRecoilState(shipmentRequestState);
  const [shipmentDetail, setSaveShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );
  const [isChinaImport, setIsChinaImport] = useRecoilState(chinaImportState);
  const [, setFilteredLinks] = useRecoilState(filteredShopNShipLinksState);
  const [, setShow] = useRecoilState(warningState);
  const history = useHistory();

  const handleImportSelection = option => () => {
    setSelectedImport(option);

    if (option.title === bookImportTypes[0]?.title) {
      setSaveShipmentDetail({
        ...shipmentDetail,
        destinationType: shipmentDestinationTypes[0].value,
        itemCollectionMode: shipmentMethods[0].value
      });
      history.push(singleShipmentLinks[0].link);
    } else if (option.title === bookImportTypes[2]?.title) {
      history.push(seaFreightImportLinks[0].link);
    } else if (option.title === bookImportTypes[1]?.title) {
      setIsChinaImport(true);
      const filteredShopNShipLinks = shopNShipLinks.filter(link =>
        isChinaImport
          ? link.name !== 'Personal Information' &&
            link.name !== 'Shop & Ship Summary' &&
            link.name !== 'Shop & Ship Payment' &&
            link.name !== 'Shop & Ship Successful'
          : link.name !== 'Delivery Option' &&
            // link.name !== 'Welcome' &&
            link.name !== 'Order Summary' &&
            link.name !== 'Payment' &&
            link.name !== 'Delivery Address'
      );

      setFilteredLinks(filteredShopNShipLinks);

      history.push(filteredShopNShipLinks[0].link);
    }
  };

  const handleBackButtonClick = () => {
    setRequestPage(requestPage => requestPage - 1);
    setSelectedImport({});
    const scrollValue = isMiniMobile ? modal.current?.clientHeight - 70 / 3 : 0;
    scroll && scroll(scrollValue);
  };

  return (
    <div ref={modal} className='book-shipment-modal-contents'>
      <BusyOverlay loading={getUserResult.loading} />
      <div className='book-shipment-modal-header'>
        <h3>Book An Import</h3>
        <div className='book-shipment-modal-close-button'>
          <IconButton
            onClick={() => {
              setShow(true);
            }}
          >
            <p>
              <Close id='close-button' />
            </p>
          </IconButton>
        </div>
      </div>
      <div className='book-shipment-modal-content'>
        <p className='book-shipment-content-title'>
          What would you like to do?
        </p>

        {bookImportTypes.map((option, index) => (
          <BookShipmentContentItem
            key={index}
            onClick={handleImportSelection(option)}
            selected={selectedImport.title === option.title}
            option={option}
          />
        ))}
      </div>
      <div className='book-shipment-modal-footer'>
        <div
          className='book-shipment-modal-button book-shipment-modal-cancel-button'
          onClick={handleBackButtonClick}
        >
          <span>Back</span>
        </div>
      </div>
    </div>
  );
};

export default BookImportOptions;
