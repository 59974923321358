import Jet2 from '../../assets/image/white-jet1.png';
import Rates from '../../assets/image/top-rate.png';
import { useRef } from 'react';
import Close from '../../assets/svg/close.svg';
import Download from '../../assets/svg/download.svg';
import RoundupLogo from '../../assets/image/roundup-white.png';
import { userRoundUpDataState } from '../../recoil/atoms';
import { useRecoilState } from 'recoil';
import ShareButtons from '../Commons/share-buttons';

const RateRoundUpPrompt = ({
  openPrompt,
  setOpenPrompt,
  setOpenMainPrompt,
  downloadDisplay,
  setDownloadDisplay,
  getImage,
  handleShareLink,
  closeSocials
}) => {
  const [roundupData] = useRecoilState(userRoundUpDataState);
  const closeCurrentPrompt = () => {
    setOpenPrompt(false);
    setOpenMainPrompt(true);
    setDownloadDisplay(false);
  };
  const componentRef = useRef();

  return (
    <>
      <div className={`round-up__prompt fade${openPrompt ? ' show' : ''}`}>
        <div>
          <div className='round-up__prompt__wrap'>
            <div className={`${downloadDisplay ? 'whiteBg' : ''}`}>
              <div
                className={`rate-total__body ${downloadDisplay ? 'small' : ''}`}
              >
                <div className='padded-flex'>
                  <img
                    onClick={() => getImage(componentRef.current)}
                    style={{ cursor: 'pointer' }}
                    src={Download}
                    alt='download'
                  />
                  <img
                    onClick={closeCurrentPrompt}
                    style={{ cursor: 'pointer' }}
                    src={Close}
                    alt='close'
                  />
                </div>
                <div ref={componentRef} className='rate-total__body__wrap'>
                  <div className='rate-total__shipped-total'>
                    <div className='shipped-content'>
                      <div>
                        <img
                          src={RoundupLogo}
                          alt='round up logo'
                          className='roundup-logo-small'
                        />
                        <img
                          src={Jet2}
                          alt='jet'
                          className={downloadDisplay ? 'tiny white-jet' : ''}
                          style={{
                            marginLeft: '70%'
                          }}
                        />
                        {roundupData?.userPercentage <= 10 ? (
                          <h5
                            className={`content-text-${
                              downloadDisplay ? 'small-rate' : 'large-rate'
                            }`}
                          >
                            You're in the
                            <br />
                            top {roundupData?.userPercentage}
                            % of our <br /> customers.
                            <br />
                            Thank you. <br /> There's no <br /> Topship
                            <br /> Without you!
                          </h5>
                        ) : (
                          <h5
                            className={`content-text-${
                              downloadDisplay ? 'small-rate' : 'large-rate'
                            }`}
                          >
                            You're in the
                            <br />
                            top {roundupData?.userPercentage}
                            % of our <br /> customers.
                            <br />
                            Literally, you're <br /> the building <br /> block
                            of
                            <br /> Topship
                          </h5>
                        )}
                      </div>
                    </div>
                    <div className='roundup-total-img'>
                      <img src={Rates} alt='gift boxes' />
                    </div>
                  </div>
                </div>
              </div>
              {downloadDisplay && (
                <ShareButtons
                  handleShareLink={handleShareLink}
                  closeSocials={closeSocials}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`round-up__prompt__backdrop fade${
          openPrompt ? ' show' : ''
        }`}
      ></div>
    </>
  );
};

export default RateRoundUpPrompt;
