import { CheckCircle } from '@mui/icons-material';

export const shopNShipLinks = [
  {
    name: 'Welcome',
    link: '/shop-and-ship-welcome'
  },
  {
    name: 'Delivery Information',
    link: '/shop-and-ship-package-information'
  },
  {
    name: 'Item Information',
    link: '/shop-and-ship-item-information'
  },
  {
    name: 'Shipping Information',
    link: '/shop-and-ship-shipping-information'
  },

  {
    name: 'Other Information',
    link: '/shop-and-ship-other-information'
  },
  {
    name: 'Shop & Ship Summary',
    link: '/shop-and-ship-summary'
  },
  {
    name: 'Order Summary',
    link: '/shop-and-ship-summary'
  },
  // {
  //   name: 'Shop & Ship Payment',
  //   link: '/shop-and-ship-payment/'
  // },

  // {
  //   name: 'Payment',
  //   link: '/shop-and-ship-payment/'
  // },

  {
    name: 'Shop & Ship Successful',
    link: '/shop-and-ship-successful',
    icon: <CheckCircle />
  },
  {
    name: 'Delivery Address',
    link: '/shop-and-ship-delivery-address'
  }
];
