import React, { useEffect } from 'react';
import { Alert, Button, Grid, Box } from '@mui/material';
import Input from '../../../../../components/new-dashboard/custom-input';
import SubmitButton from '../../../../../components/submit-button';
import { useFormik } from 'formik';
import numberWithCommas from '../../../../../utilities/number-with-commas';
import { toCurrency } from '../../../../../utilities/to-currency';
import * as yup from 'yup';
import { useRecoilState } from 'recoil';
import {
  chinaImportState,
  filteredShopNShipLinksState,
  saveItemCategoriesState,
  shopNShipDetailsState
} from '../../../../../recoil/atoms';
import NewShopNShipStepsLayout from '../../../../../components/Layouts/shop-n-ship-steps-layout';
import { useHistory } from 'react-router-dom';
import {
  // itemCategories,
  itemCategoriesChinaImport
} from '../../../../../constants/item-categories';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { ReactComponent as InfoIcon } from '../../../../../assets/svg/info-icon-three.svg';
import { useState } from 'react';
import testJSON from '../../../../../utilities/test-json';
import axios from 'axios';
import { useSendAlert } from '../../../../../utilities/send-alert';
import StickyNoteAlert from '../../../../../components/Commons/sticky-note';
import VolumetricWeight from '../../../../../components/volumetric-weight-switch';
import { segregatedChinaCategories } from '../../../../../constants/china-categories';
import CustomAutoComplete from '../../../../../components/new-dashboard/custom-autocomplete';
import FileUpload from '../../../../../components/file-upload';
import { YellowInfo } from '../../../../../constants/asset-contants';
import HowItWorks from '../../../../../components/how-it-works';
import HowItWorksTrigger from '../../../../../components/how-it-works-trigger';

const defaultValues = [
  {
    trackingNumber: '',
    amountDue: 0,
    estimatedWeight: 0,
    width: 0,
    height: 0,
    length: 0,
    category: '',
    quantity: '',
    additionalInformation: '',
    proofOfId: '',
    evidenceOfPurchase: '',
    isVolumetric: false
    // shippingFrom: ''
  }
];

const ONE_MB = 1048576;

const ShopNShipItemInformation = () => {
  const history = useHistory();

  const sendAlert = useSendAlert();

  const [shopNshipDetails, setSaveShopNshipDetails] = useRecoilState(
    shopNShipDetailsState
  );

  const [itemCategories] = useRecoilState(saveItemCategoriesState);

  const [filteredLinks] = useRecoilState(filteredShopNShipLinksState);

  const [, setIsValueRate] = useState(false);

  const currentIndex = filteredLinks.findIndex(
    link => link.link === history.location.pathname
  );

  const [isChinaImport] = useRecoilState(chinaImportState);

  const [initialValues, setInitialValues] = useState(defaultValues);

  const [proofOfIdLoading, setProofOfIdFileLoading] = useState(false);

  const [openHowItWorks, setOpenHowItWorks] = useState(false);

  const [
    evidenceOfPurchaseLoading,
    setEvidenceOfPurchaseFileLoading
  ] = useState(false);

  const validationSchema = yup.object().shape({
    items: yup.array().of(
      yup.object().shape({
        trackingNumber: yup
          .string()
          .test(
            'conditional-tracking-number',
            'Please enter tracking number',
            function (value) {
              if (isChinaImport) {
                return value !== undefined && value.trim() !== '';
              }

              return true;
            }
          ),
        // trackingNumber: yup.string().when(['shippingFrom'], {
        //   is: shippingFrom => shippingFrom === 'CHINA',
        //   then: yup.string().notRequired(),
        //   otherwise: yup.string().required('Please enter a tracking number')
        // }),
        amountDue: yup
          .number()
          .min(1, 'Please enter an value greater than 1')
          .required('Please enter an amount due'),
        estimatedWeight: yup.number().when(['isVolumetric'], {
          is: isVolumetric => !isVolumetric,
          then: yup
            .number()
            .min(0.09999, 'Please enter a weight greater than zero (0)')
            .required('Please enter an estimated weight of your item(s)'),
          otherwise: yup.number().nullable().notRequired()
        }),
        width: yup.number().when(['isVolumetric'], {
          is: isVolumetric => isVolumetric === true,

          then: yup
            .number()
            .min(0.09999, 'Please enter a width greater than zero (0)')
            .required('Please enter the width of your box'),
          otherwise: yup.number().nullable().notRequired()
        }),
        height: yup.number().when(['isVolumetric'], {
          is: isVolumetric => isVolumetric === true,

          then: yup
            .number()
            .min(0.09999, 'Please enter a height greater than zero (0)')
            .required('Please enter the height of your box'),
          otherwise: yup.number().nullable().notRequired()
        }),
        length: yup.number().when(['isVolumetric'], {
          is: isVolumetric => isVolumetric === true,

          then: yup
            .number()
            .min(0.09999, 'Please enter a length greater than zero (0)')
            .required('Please enter the length of your box'),
          otherwise: yup.number().nullable().notRequired()
        }),
        category: yup.string().required('Please select the Item Category'),
        quantity: yup
          .number()
          .min(1, 'Please enter an value greater than 1')
          .required('Please enter the quantity'),
        additionalInformation: yup
          .string()
          .required('Please enter Item Description'),
        proofOfId: yup.string().when(['shippingFrom'], {
          is: shippingFrom => shippingFrom === 'CHINA',
          then: yup.string().notRequired(),
          otherwise: yup
            .string()
            .required('Please upload proof of identification')
        }),
        evidenceOfPurchase: yup.string().when(['shippingFrom'], {
          is: shippingFrom => shippingFrom === 'CHINA',
          then: yup.string().notRequired(),
          otherwise: yup.string().required('Please upload evidence of purchase')
        })
      })
    )
  });

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue
  } = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      items: initialValues
    },

    onSubmit: values => {
      const itemDescription = values.items.map(item => {
        delete item.value;
        // delete item.shippingFrom;
        let estimatedWeight = item.estimatedWeight;
        if (item.isVolumetric) {
          estimatedWeight = Number(
            (
              (item.width * item.height * item.length) /
              (isChinaImport ? 6000 : 5000)
            ).toFixed(2)
          );
        }

        return {
          ...item,
          estimatedWeight,
          amountDue: item.amountDue,
          isCompleted: true
        };
      });
      const newItems = JSON.stringify(itemDescription);
      setSaveShopNshipDetails({
        ...shopNshipDetails,
        itemDescription: newItems
      });
      history.push(filteredLinks[currentIndex + 1].link);
    }
  });

  const deleteItem = index => {
    const newValues = values.items.filter(
      (_, itemIndex) => itemIndex !== index
    );
    setInitialValues(newValues);
  };

  const addItem = () => {
    const newValues = defaultValues.map(item => ({
      ...item,
      shippingFrom: isChinaImport ? 'CHINA' : ''
    }));
    setInitialValues([...values.items, ...newValues]);
  };

  const currentItemCategory = itemCategories;

  const handleProofOfIdFileChange = (file, index) => {
    if (!file) {
      return sendAlert('File not provided.', 'warning');
    }

    const fileType = new RegExp('png|jpeg|jpg|webp|pdf|doc|docx');

    if (!file.type || !file?.type.match(fileType)) {
      return sendAlert('File format not supported.', 'error');
    }

    if (file.size > ONE_MB) {
      return sendAlert('Please upload a file less than 1MB', 'error');
    }

    setProofOfIdFileLoading(true);

    const url = `${process.env.REACT_APP_REST_API}/upload`;

    const formData = new FormData();

    formData.append('file', file);

    formData.append('fileName', file.name);

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    axios
      .post(url, formData, config)
      .then(response => {
        setFieldValue(`items.${index}.proofOfId`, response.data.Location);

        setProofOfIdFileLoading(false);
      })
      .catch(err => {
        sendAlert(err?.message, 'error');
        setProofOfIdFileLoading(false);
      });
  };

  const handleEvidenceOfPurchaseFileChange = (file, index) => {
    if (!file) {
      return sendAlert('File not provided.', 'warning');
    }
    const fileType = new RegExp('png|jpeg|jpg|webp|pdf|doc|docx');

    if (!file.type || !file?.type.match(fileType)) {
      return sendAlert('File format not supported.', 'error');
    }

    if (file.size > ONE_MB) {
      return sendAlert('Please upload a file less than 1MB', 'error');
    }

    setEvidenceOfPurchaseFileLoading(true);

    const url = `${process.env.REACT_APP_REST_API}/upload`;
    const formData = new FormData();

    formData.append('file', file);
    formData.append('fileName', file.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    axios
      .post(url, formData, config)
      .then(response => {
        setFieldValue(
          `items.${index}.evidenceOfPurchase`,
          response.data.Location
        );

        setEvidenceOfPurchaseFileLoading(false);
      })
      .catch(err => {
        sendAlert(err?.message, 'error');
        setEvidenceOfPurchaseFileLoading(false);
      });
  };

  const handleIsVolumetricChange = (fieldName, newValue) => {
    setFieldValue(fieldName, newValue);
  };

  useEffect(() => {
    if (shopNshipDetails) {
      const { itemDescription } = shopNshipDetails;
      if (itemDescription && testJSON(itemDescription)) {
        const items = JSON.parse(itemDescription);

        const newItems = items.map(item => ({
          ...item,
          amountDue: item.amountDue,
          proofOfId: item?.proofOfId || '',
          evidenceOfPurchase: item?.evidenceOfPurchase || '',
          shippingFrom: isChinaImport ? 'CHINA' : ''
        }));

        setInitialValues(newItems);

        setIsValueRate(true);
      } else {
        setInitialValues([
          {
            amountDue: isChinaImport ? '' : shopNshipDetails?.amountDue || '',
            category: shopNshipDetails?.category || '',
            estimatedWeight: shopNshipDetails?.estimatedWeight || '',
            height: shopNshipDetails?.height || '',
            width: shopNshipDetails?.width || '',
            length: shopNshipDetails?.length || '',
            additionalInformation: shopNshipDetails?.itemDescription || '',
            quantity: shopNshipDetails?.quantity || '',
            trackingNumber: shopNshipDetails?.trackingNumber || '',
            proofOfId: shopNshipDetails?.proofOfId || '',
            evidenceOfPurchase: shopNshipDetails?.evidenceOfPurchase || '',
            shippingFrom: isChinaImport ? 'CHINA' : ''
          }
        ]);
        setIsValueRate(true);
      }
    }
    // eslint-disable-next-line
  }, [shopNshipDetails, isChinaImport]);

  return (
    <NewShopNShipStepsLayout mobileTitle='Item Information'>
      {openHowItWorks ? (
        <HowItWorks
          openHowItWorks={openHowItWorks}
          setOpenHowItWorks={setOpenHowItWorks}
        />
      ) : null}
      <div className='new-shop-n-ship__modal__body'>
        {isChinaImport ? null : (
          <HowItWorksTrigger setOpenHowItWorks={setOpenHowItWorks} />
        )}

        <div className='new-shop-n-ship__modal__body__wrap'>
          <div className='new-shop-n-ship__modal__body__content'>
            <form
              onSubmit={handleSubmit}
              // className='new-shop-n-ship__modal__body__form'
              className='item-description'
            >
              <label className='item-description__label'>Add Items</label>
              <div className='item-description__mains'>
                {values.items.map((item, index) => (
                  <div key={index} className='item-description__main mt-3'>
                    <div className='item-description__main__wrap'>
                      <div className='item-description__main__action'>
                        {values.items.length > 1 && (
                          <Button
                            variant='text'
                            onClick={() => deleteItem(index)}
                          >
                            <span>Remove item</span>
                            <RemoveCircle />
                          </Button>
                        )}
                      </div>
                      <div className='item-description__main__grid'>
                        <Grid
                          container
                          rowSpacing='20px'
                          columnSpacing='16px'
                          className='item-description__main__grid__form'
                        >
                          <CustomAutoComplete
                            options={currentItemCategory}
                            getOptionLabel={option =>
                              option ? option?.category : ''
                            }
                            isOptionEqualToValue={(option, value) =>
                              option?.value === value?.value
                            }
                            renderOption={(props, option) => (
                              <Box key={props.id} component='li' {...props}>
                                {option?.category}
                              </Box>
                            )}
                            value={
                              currentItemCategory.find(
                                category => category.value === item.category
                              ) || { category: '', value: '' }
                            }
                            noOptionsText={'Item Category not found'}
                            onChange={(event, value, reason) => {
                              if (reason === 'clear') {
                                setFieldValue(`items.${index}.category`, '');
                              } else {
                                setFieldValue(
                                  `items.${index}.category`,
                                  value?.value
                                );
                              }
                            }}
                            textfieldProps={{
                              lg: 6,
                              md: 6,
                              name: `items.${index}.category`,
                              customLabel: 'Select Item Category',
                              containerClassName:
                                'item-description__main__input',
                              value: item.category,
                              error: Boolean(
                                touched.items?.[index]?.category &&
                                  errors.items?.[index]?.category
                              ),
                              helperText:
                                touched.items?.[index]?.category &&
                                errors.items?.[index]?.category,
                              onChange: handleChange
                            }}
                          />

                          <Input
                            name={`items.${index}.amountDue`}
                            value={`${
                              item.amountDue
                                ? isChinaImport
                                  ? item.amountDue
                                  : numberWithCommas(item.amountDue)
                                : 0
                            }`}
                            onChange={({ target: { name, value } }) => {
                              if (!value.length) {
                                setFieldValue(name, 0);
                              }
                              if (isNaN(value.replaceAll(',', ''))) {
                                return;
                              }
                              const fundingAmount = parseInt(
                                value.split(',').join(''),
                                10
                              );
                              setFieldValue(name, fundingAmount);
                            }}
                            error={
                              touched.items?.[index]?.amountDue &&
                              Boolean(errors.items?.[index]?.amountDue)
                            }
                            helperText={
                              touched.items?.[index]?.amountDue &&
                              errors.items?.[index]?.amountDue
                            }
                            customLabel='Item value (₦)'
                            // customLabel={`Item value  ${
                            //   isChinaImport ? '($)' : '(₦)'
                            // } `}
                            type='text'
                            placeholder={toCurrency(1000)}
                            containerClassName='new-shop-n-ship__modal__body__form__item__input'
                            lg={6}
                            md={12}
                          />
                          {isChinaImport ? (
                            <Alert
                              severity={'info'}
                              sx={{
                                fontSize: '1.5rem',
                                fontFamily: 'General Sans',
                                fontWeight: 500,
                                marginTop: '10px',
                                backgroundColor: '#F3F6F9',
                                marginLeft: '15px',
                                color: '#22428F',
                                border: 'none',
                                '& .MuiAlert-icon': {
                                  display: 'none'
                                }
                              }}
                            >
                              {segregatedChinaCategories.includes(item.category)
                                ? '💰This cost $23 per KG and includes customs clearance'
                                : '💰This cost $15 per KG and includes customs clearance'}
                            </Alert>
                          ) : null}
                          {isChinaImport ? (
                            <Input
                              name={`items.${index}.trackingNumber`}
                              value={item.trackingNumber}
                              error={
                                touched.items?.[index]?.trackingNumber &&
                                Boolean(errors.items?.[index]?.trackingNumber)
                              }
                              helperText={
                                touched.items?.[index]?.trackingNumber &&
                                errors.items?.[index]?.trackingNumber
                              }
                              onChange={handleChange}
                              customLabel={`Order Tracking Number (${
                                isChinaImport
                                  ? 'from the supplier/manufacturer'
                                  : 'from the retailer'
                              }) `}
                              type='text'
                              placeholder='#000000000000'
                              containerClassName='new-shop-n-ship__modal__body__form__item__input'
                              fullWidth
                            />
                          ) : null}
                          <Input
                            name={`items.${index}.additionalInformation`}
                            value={item.additionalInformation}
                            error={
                              touched.items?.[index]?.additionalInformation &&
                              Boolean(
                                errors.items?.[index]?.additionalInformation
                              )
                            }
                            helperText={
                              touched.items?.[index]?.additionalInformation &&
                              errors.items?.[index]?.additionalInformation
                            }
                            onChange={handleChange}
                            customLabel='Item Description'
                            type='text'
                            multiline
                            minRows={4}
                            placeholder='A piece of text that clearly describes the item being packaged for shipping, it should leave no room for guesses so that TopShip can know how best to handle it'
                            containerClassName='new-shop-n-ship__modal__body__form__item__input'
                            fullWidth
                          />
                        </Grid>
                        {/* <Input
                            name={`items.${index}.quantity`}
                            value={item.quantity}
                            onChange={handleChange}
                            error={
                              touched.items?.[index]?.quantity &&
                              Boolean(errors.items?.[index]?.quantity)
                            }
                            helperText={
                              touched.items?.[index]?.quantity &&
                              errors.items?.[index]?.quantity
                            }
                            customLabel='Quantity'
                            type='number'
                            placeholder={'20'}
                            containerClassName='new-shop-n-ship__modal__body__form__item__input'
                            lg={6}
                            md={12}
                          />
                          <Input
                            name={`items.${index}.estimatedWeight`}
                            value={item.estimatedWeight}
                            error={
                              touched.items?.[index]?.estimatedWeight &&
                              Boolean(errors.items?.[index]?.estimatedWeight)
                            }
                            helperText={
                              touched.items?.[index]?.estimatedWeight &&
                              errors.items?.[index]?.estimatedWeight
                            }
                            onChange={handleChange}
                            customLabel='Estimated Weight (KG)'
                            type='number'
                            placeholder='45'
                            containerClassName='new-shop-n-ship__modal__body__form__item__input'
                            lg={6}
                            md={12}
                          /> */}
                        <div className=''>
                          <VolumetricWeight
                            checkBoxName={`items.${index}.isVolumetric`}
                            isActive={item.isVolumetric}
                            handleToggleSwitch={() =>
                              handleIsVolumetricChange(
                                `items.${index}.isVolumetric`,
                                !item.isVolumetric
                              )
                            }
                          />
                        </div>
                        {item.isVolumetric ? (
                          <div
                            style={{
                              marginTop: '30px'
                            }}
                          >
                            <Grid
                              container
                              columnSpacing='10px'
                              rowSpacing='16px'
                              className='item-description__main__grid__form'
                            >
                              <Input
                                lg={6}
                                md={12}
                                type='number'
                                name={`items.${index}.quantity`}
                                placeholder='20'
                                value={item.quantity}
                                onChange={e => {
                                  if (isNaN(e.target.value)) {
                                    return handleChange({
                                      target: {
                                        name: e.target.name,
                                        value: ''
                                      }
                                    });
                                  } else {
                                    handleChange(e);
                                  }
                                }}
                                customLabel='Quantity'
                                error={Boolean(errors.items?.[index]?.quantity)}
                                helperText={errors.items?.[index]?.quantity}
                                containerClassName='item-description__main__input'
                                inputProps={{
                                  min: 1
                                }}
                              />
                              <Input
                                lg={6}
                                md={12}
                                containerClassName='item-description__main__input'
                                type='number'
                                name={`items.${index}.width`}
                                customLabel='Width (CM)'
                                placeholder='Enter Width (CM)'
                                value={item.width}
                                onChange={e => {
                                  if (isNaN(e.target.value)) {
                                    return handleChange({
                                      target: {
                                        name: e.target.name,
                                        value: ''
                                      }
                                    });
                                  } else {
                                    handleChange(e);
                                  }
                                }}
                                error={Boolean(errors.items?.[index]?.width)}
                                helperText={errors.items?.[index]?.width}
                                inputProps={{
                                  min: 1,
                                  step: 'any'
                                }}
                              />
                            </Grid>
                            <Grid
                              container
                              columnSpacing='10px'
                              rowSpacing='16px'
                              className='item-description__main__grid__form mt-1'
                            >
                              <Input
                                lg={6}
                                md={12}
                                containerClassName='item-description__main__input'
                                type='number'
                                name={`items.${index}.length`}
                                customLabel='Length (CM)'
                                placeholder='Enter Length (CM)'
                                value={item.length}
                                onChange={e => {
                                  if (isNaN(e.target.value)) {
                                    return handleChange({
                                      target: {
                                        name: e.target.name,
                                        value: ''
                                      }
                                    });
                                  } else {
                                    handleChange(e);
                                  }
                                }}
                                error={Boolean(errors.items?.[index]?.length)}
                                helperText={errors.items?.[index]?.length}
                                inputProps={{
                                  min: 1,
                                  step: 'any'
                                }}
                              />
                              <Input
                                lg={6}
                                md={12}
                                containerClassName='item-description__main__input'
                                type='number'
                                name={`items.${index}.height`}
                                customLabel='Height (CM)'
                                placeholder='Enter Height'
                                value={item.height}
                                onChange={e => {
                                  if (isNaN(e.target.value)) {
                                    return handleChange({
                                      target: {
                                        name: e.target.name,
                                        value: ''
                                      }
                                    });
                                  } else {
                                    handleChange(e);
                                  }
                                }}
                                error={Boolean(errors.items?.[index]?.height)}
                                helperText={errors.items?.[index]?.height}
                                inputProps={{
                                  min: 1,
                                  step: 'any'
                                }}
                              />
                            </Grid>
                          </div>
                        ) : (
                          <>
                            <a
                              href={'https://docdro.id/khtz9i8'}
                              target='_blank'
                              rel='noreferrer'
                              style={{
                                textDecoration: 'none',
                                transform: 'none'
                              }}
                              className='item-description__main__grid__helper'
                            >
                              NEED HELP?
                            </a>

                            <Grid
                              container
                              columnSpacing='10px'
                              rowSpacing='16px'
                              className='item-description__main__grid__form'
                            >
                              <Input
                                name={`items.${index}.quantity`}
                                value={item.quantity}
                                onChange={handleChange}
                                error={
                                  touched.items?.[index]?.quantity &&
                                  Boolean(errors.items?.[index]?.quantity)
                                }
                                helperText={
                                  touched.items?.[index]?.quantity &&
                                  errors.items?.[index]?.quantity
                                }
                                customLabel='Quantity'
                                type='number'
                                placeholder={'20'}
                                containerClassName='new-shop-n-ship__modal__body__form__item__input'
                                lg={6}
                                md={12}
                              />
                              <Input
                                name={`items.${index}.estimatedWeight`}
                                value={item.estimatedWeight}
                                error={
                                  touched.items?.[index]?.estimatedWeight &&
                                  Boolean(
                                    errors.items?.[index]?.estimatedWeight
                                  )
                                }
                                helperText={
                                  touched.items?.[index]?.estimatedWeight &&
                                  errors.items?.[index]?.estimatedWeight
                                }
                                onChange={handleChange}
                                customLabel='Estimated Weight (KG)'
                                type='number'
                                placeholder='45'
                                containerClassName='new-shop-n-ship__modal__body__form__item__input'
                                lg={6}
                                md={12}
                              />
                            </Grid>
                          </>
                        )}
                        {isChinaImport ? null : (
                          <>
                            <div id='input-control'>
                              <label htmlFor='proofOfId'>
                                <span style={{ marginBottom: '10px' }}>
                                  Upload Proof Of Identification
                                </span>
                                <p className='data__description-text'>
                                  (Government-issued ID e.g NIN, Drivers
                                  License, Voter’s License, Passport)
                                </p>
                              </label>

                              <FileUpload
                                onUploadFile={file =>
                                  handleProofOfIdFileChange(file, index)
                                }
                                uploadedFile={item?.proofOfId}
                                loading={proofOfIdLoading}
                              />
                              <small>
                                {touched.items?.[index]?.proofOfId &&
                                  errors.items?.[index]?.proofOfId}
                              </small>
                            </div>

                            <div id='input-control'>
                              <label htmlFor='evidenceOfPurchase'>
                                <span style={{ marginBottom: '10px' }}>
                                  Upload Evidence of Purchase
                                </span>
                                <p className='data__description-text'>
                                  (Bank statement or detailed transaction
                                  receipt)
                                </p>
                              </label>
                              <div className='data__validity-info--box'>
                                <InfoIcon />
                                <p className='data__validity-info'>
                                  <strong>Please note:</strong> The name on the
                                  proof of purchase should match the name on
                                  your uploaded ID
                                </p>
                              </div>
                              <FileUpload
                                onUploadFile={file =>
                                  handleEvidenceOfPurchaseFileChange(
                                    file,
                                    index
                                  )
                                }
                                uploadedFile={item?.evidenceOfPurchase}
                                loading={evidenceOfPurchaseLoading}
                              />
                              <small>
                                {touched.items?.[index]?.evidenceOfPurchase &&
                                  errors.items?.[index]?.evidenceOfPurchase}
                              </small>
                            </div>
                          </>
                        )}

                        <div className='new-shop-n-ship__notice mt-3'>
                          <img src={YellowInfo} alt='' />
                          <p>
                            Upload a valid proof of purchase to reduce shipment
                            processing time. There will be a two-week holding
                            period for high-value items delivered to our
                            addresses without a valid proof of purchase.
                          </p>
                        </div>

                        <div className='mt-3' />

                        <StickyNoteAlert
                          isVolumetric={true}
                          chinaImport={isChinaImport}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className='item-description__action'>
                <Button
                  variant='text'
                  disabled={values.items.length >= 5}
                  onClick={addItem}
                >
                  <span>Add another item</span>
                  <AddCircle />
                </Button>
              </div>
              {isChinaImport ? (
                <div className='mb-2'>
                  <Alert
                    severity={'info'}
                    sx={{
                      marginTop: '10px',
                      fontSize: '1.5rem',
                      fontWeight: 'medium',
                      fontFamily: 'General Sans',
                      backgroundColor: '#E1F9FF',
                      color: '#416EB2',
                      border: 'none',
                      '& .MuiAlert-icon': {
                        fontSize: '1.6rem',
                        marginRight: '5px',
                        marginTop: '2px',
                        color: '#416EB2'
                      }
                    }}
                  >
                    Please note that your package will be reweighed before it is
                    shipped out. The weight of the package determined at the
                    warehouse/partner hub overrides the weight you enter in this
                    form and you will be notified accordingly.
                  </Alert>
                </div>
              ) : null}
              <div className='new-shop-n-ship__modal__body__form__item__buttons'>
                <div
                  className='new-shop-n-ship__modal__body__form__item__button'
                  onClick={() =>
                    history.push(filteredLinks[currentIndex - 1].link)
                  }
                >
                  Go Back
                </div>
                <SubmitButton text='Continue' />
              </div>
            </form>
          </div>
        </div>
      </div>
    </NewShopNShipStepsLayout>
  );
};

export default ShopNShipItemInformation;
