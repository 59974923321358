import Jet2 from '../../assets/image/white-jet1.png';
import Jet from '../../assets/image/white-jet2.png';
import LondonExports from '../../assets/image/london-exports.png';
import Close from '../../assets/svg/close.svg';
import Download from '../../assets/svg/download.svg';
import RoundupLogo from '../../assets/image/roundup-white.png';
import { userRoundUpDataState } from '../../recoil/atoms';
import { useRecoilState } from 'recoil';
import { useEffect, useRef, useState } from 'react';
import ShareButtons from '../Commons/share-buttons';

const ExportRoundUpPrompt = ({
  openPrompt,
  setOpenPrompt,
  setOpenMainPrompt,
  downloadDisplay,
  setDownloadDisplay,
  getImage,
  closeSocials,
  handleShareLink
}) => {
  const [roundupData] = useRecoilState(userRoundUpDataState);
  const componentRef = useRef();
  const closeCurrentPrompt = () => {
    setOpenMainPrompt(true);
    setOpenPrompt(false);
    setDownloadDisplay(false);
  };

  const topCitiesShippedTo = !!roundupData?.topCitiesShippedTo
    ? [...roundupData?.topCitiesShippedTo]?.sort((a, b) => b.count - a.count)
    : [];

  const topCityShippedTo = topCitiesShippedTo.length
    ? topCitiesShippedTo?.[0]?.city?.toLowerCase()
    : '';

  return (
    <>
      <div className={`round-up__prompt fade${openPrompt ? ' show' : ''}`}>
        <div>
          <div className='round-up__prompt__wrap'>
            <div className={`${downloadDisplay ? 'whiteBg' : ''}`}>
              <div
                className={`export-total__body ${
                  downloadDisplay ? 'small' : ''
                }`}
              >
                <div className='padded-flex'>
                  <img
                    onClick={() => getImage(componentRef.current)}
                    style={{ cursor: 'pointer' }}
                    src={Download}
                    alt='download'
                  />
                  <img
                    onClick={closeCurrentPrompt}
                    style={{ cursor: 'pointer' }}
                    src={Close}
                    alt='close'
                  />
                </div>
                <div ref={componentRef} className='export-total__body__wrap'>
                  <div className='export-total__shipped-total'>
                    <div className='shipped-content'>
                      <div>
                        <img
                          src={RoundupLogo}
                          alt='round up logo'
                          className='roundup-logo-small'
                        />
                        <img
                          src={Jet2}
                          alt='jet'
                          className={`absolute-jet-small ${
                            downloadDisplay ? 'tiny' : ''
                          }`}
                        />
                        {topCityShippedTo.length > 0 ? (
                          <h5
                            className={`content-text-${
                              downloadDisplay ? 'small' : 'large'
                            }`}
                          >
                            <span
                              style={{
                                textTransform: 'capitalize'
                              }}
                            >
                              {topCityShippedTo || ''}
                            </span>{' '}
                            <br />
                            was your <br /> no. 1 shipping <br /> destination in
                            2022
                          </h5>
                        ) : (
                          <h5
                            className={`content-text-${
                              downloadDisplay ? 'small' : 'large'
                            }`}
                          >
                            You haven't
                            <br />
                            shipped this
                            <br /> year!
                          </h5>
                        )}
                        <div className='absolute-jet2'>
                          <img src={Jet} alt='gift box' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {downloadDisplay && (
                <ShareButtons
                  handleShareLink={handleShareLink}
                  closeSocials={closeSocials}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`round-up__prompt__backdrop fade${
          openPrompt ? ' show' : ''
        }`}
      ></div>
    </>
  );
};

export default ExportRoundUpPrompt;
