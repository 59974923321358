import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { allowedIntraCityUsers } from '../constants/book-shipment-contants';
import { useGetUserLazyQuery } from '../operations/queries';
import { saveShipmentDetailsState } from '../recoil/atoms';
import { useSendAlert } from './send-alert';

export function useFilterIntracityShipments() {
  const [shipmentDetail] = useRecoilState(saveShipmentDetailsState);
  const sendAlert = useSendAlert();
  const [getUser, { data }] = useGetUserLazyQuery();
  const user = data?.user || { topshipId: '' };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  const filterStates = state => {
    return true;
    // return state?.countryCode?.toLowerCase() !== 'ng'
    //   ? true
    //   : !!allowedIntraCityUsers.find(
    //       topshipId => user?.topshipId === topshipId
    //     ) ||
    //       (state?.countryCode?.toLowerCase() === 'ng' &&
    //         state?.name?.toLowerCase() !==
    //           shipmentDetail?.senderDetail?.state?.toLowerCase());
  };

  const testState = ({ state, countryCode }) => {
    return true;
    // const isInterCity =
    //   !!allowedIntraCityUsers.find(
    //     topshipId => user?.topshipId === topshipId
    //   ) ||
    //   (countryCode?.toLowerCase() === 'ng' &&
    //     state?.toLowerCase() !==
    //       shipmentDetail?.senderDetail?.state?.toLowerCase());
    // if (!isInterCity && countryCode?.toLowerCase() === 'ng') {
    //   sendAlert('Intracity deliveries are currently not supported', 'error');
    // }
    // return countryCode?.toLowerCase() !== 'ng' ? true : isInterCity;
  };

  return { filterStates, testState };
}
