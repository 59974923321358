import React, { useEffect, useMemo } from 'react';
import ReactGA from 'react-ga';
import NewShippingStepsLayout from '../../../../components/Layouts/new-shipping-steps-layout';
import shipmentRateModeDictionary from '../../../../constants/shipment-rate-modes-dictionary';
import SubmitButton from '../../../../components/submit-button';
import insurancePlans, {
  extendedInsuranceLimits
} from '../../../../constants/insurance-plans';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Helmet } from 'react-helmet';
import { toCurrency } from '../../../../utilities/to-currency';
import { Alert, Radio, Skeleton } from '@mui/material';
import {
  budgetDeliveryState,
  saveLastMileState,
  savePickupRateState,
  saveShipmentDetailsState,
  saveShipmentRateState,
  selectedInsurancePlan,
  selectedLastMileDeliveryOption,
  selectedPickupPartnerState,
  selectedRateState
} from '../../../../recoil/atoms';
import EmptyList from '../../../../components/empty-list';
import { singleShipmentLinks } from '../../../../constants/shipment-links';
import {
  useGetLastMileRatesQuery,
  useGetPickupRatesQuery,
  useGetShipmentRateQuery
} from '../../../../operations/queries';
import { useSendAlert } from '../../../../utilities/send-alert';
import { checkIsCompleted } from '../../helper-functions';
import { useState } from 'react';
import getPlan from '../../../../utilities/getPlans';
import emptyStateIcon from '../../../../assets/svg/empty.svg';
import {
  lightColors,
  shippingRates,
  statesWithTopshipHubs
} from '../../../../constants/shipping-rates';
import { SelectArrowBlue } from '../../../../constants/asset-contants';
import { toFixed } from '../../../../utilities/to-fixed';
import {
  shipmentMethods,
  shipmentOptions
} from '../../../../constants/book-shipment-contants';
import {
  isImportBooking,
  isItemCollectionModeMatch,
  isWithinLagos
} from '../../../../utilities/check-item-collection-mode';
import { pickupPartnersWithBadImages } from '../../../../constants/shipment-status-dictionary';
import { DeliveryOptions1 } from '../../../../components/shipping-options';
import { TermsAndConditionsPrompt } from '../../../../constants/terms-&-condition';
import InAppSaverPrompt from '../../../../components/modals/in-app-prompt';
import SeaFreightPickupPrompt from '../../../../components/modals/sea-freight-pickup-option';
import { doorstepOption } from '../../../../constants/sea-freight-addresses';
import omit from 'lodash.omit';

const DeliveryOptions = () => {
  const history = useHistory();
  const sendAlert = useSendAlert();
  const [shipmentRates] = useRecoilState(saveShipmentRateState);
  const [pickupRates] = useRecoilState(savePickupRateState);
  const [lastMileRates] = useRecoilState(saveLastMileState);
  const [selectedRate, setSelectedRate] = useRecoilState(selectedRateState);
  const [selectedBudgetDelivery, setSelectedBudgetDelivery] = useRecoilState(
    budgetDeliveryState
  );
  const [selectedInsurance, setSelectedInsurance] = useRecoilState(
    selectedInsurancePlan
  );
  const [selectedLastMile, setSelectedLastMile] = useRecoilState(
    selectedLastMileDeliveryOption
  );
  const [openPickupOptionModal, setOpenPickupOptionModal] = useState(false);
  const [openSaverAlert, setOpenSaverAlert] = useState(false);
  const [selectedPickupPartner, setSelectedPickupPartner] = useRecoilState(
    selectedPickupPartnerState
  );
  const [isInsurancePrompt, setIsInsurancePrompt] = useState(false);
  const [saveShipmentDetail, setSaveShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );
  const [getPickupRate, { loading: pickupLoading }] = useGetPickupRatesQuery(
    {}
  );
  const [
    getLastMileRate,
    { loading: lastMileLoading }
  ] = useGetLastMileRatesQuery({});

  const [openInAppPrompt, setOpenInAppPrompt] = useState(false);

  const currentIndex = singleShipmentLinks.findIndex(
    link => history.location.pathname === link.link
  );
  const [selectedPickUpOption, setSelectedPickUpOption] = useState(
    doorstepOption
  );
  const shipmentDetail = useMemo(
    () =>
      saveShipmentDetail ||
      JSON.parse(localStorage.getItem('saveShipmentDetailsState'))
        ?.saveShipmentDetailsState,
    [saveShipmentDetail]
  );

  const selectedOption = useMemo(() => {
    if (!shipmentDetail?.shipmentRoute) {
      return null;
    }

    return shipmentOptions.find(
      option => shipmentDetail?.shipmentRoute === option.value
    );
  }, [shipmentDetail?.shipmentRoute]);

  const businessId = localStorage.getItem('lspId');

  const totalItemValue = useMemo(() => {
    if (!shipmentDetail || !shipmentDetail?.items) {
      return 0;
    }

    return (
      (shipmentDetail?.items || [])?.reduce(
        (sum, item) => sum + item?.value,
        0
      ) / 100
    );
  }, [shipmentDetail?.items]);

  const totalItemWeight = useMemo(() => {
    if (!shipmentDetail || !shipmentDetail?.items) {
      return 0;
    }

    return (shipmentDetail?.items || [])?.reduce((sum, item) => {
      if (item?.isVolumetric) {
        return sum + toFixed((item.width * item.height * item.length) / 5000);
      }

      return sum + toFixed(item?.weight);
    }, 0);
  }, [shipmentDetail?.items]);

  const [openToggle, setOpenToggle] = useState({
    delivery: true,
    pickup: true,
    insurance: true,
    lastMile: true
  });

  useEffect(() => {
    ReactGA.event({
      category: 'Shipment',
      action: 'User adds shipping items'
    });
  }, []);

  const handleRateSelection = rate => {
    setSelectedRate(rate);
  };
  const handleLastMileSelection = rate => {
    setSelectedLastMile(rate);
  };
  const handleBudgetDelivery = option => {
    setSelectedBudgetDelivery(option);
  };
  const [getRates, { loading }] = useGetShipmentRateQuery({
    transactionType: 'shipment',
    handleRateSelection
  });

  const handleInsuranceSelection = type => {
    setSelectedInsurance(type);
  };

  const handlePickUpSelection = type => {
    setSelectedPickupPartner(type);
  };

  const isBudgetImport = useMemo(() => {
    if (!selectedRate || !shipmentDetail) {
      return false;
    }

    return (
      selectedRate?.pricingTier === shippingRates[3].value &&
      shipmentDetail?.shipmentRoute === shipmentOptions[1].value
    );
  }, [selectedRate, shipmentDetail]);

  const pickupOptionMatch = useMemo(() => {
    if (!shipmentDetail) {
      return false;
    }

    return isItemCollectionModeMatch(shipmentDetail);
  }, [shipmentDetail]);

  // Pricing Tier (FedEx or DHL) for pickups outside Topship Hubs
  const pickupPricingTier = useMemo(() => {
    if (statesWithTopshipHubs.includes(shipmentDetail?.senderDetail?.state)) {
      return '';
    }

    if (!selectedRate) {
      return '';
    }

    // [Express, FedEx, SaverPriority]
    if (
      !['Express', 'FedEx', 'SaverPriority'].includes(selectedRate?.pricingTier)
    ) {
      return '';
    }

    if (selectedRate?.pricingTier === shippingRates[0].value) {
      return shippingRates[0].value;
    }

    return shippingRates[7].value;
  }, [shipmentDetail, selectedRate]);

  const handleSubmit = event => {
    event.preventDefault();
    let updatedReceiverDetail = { ...shipmentDetail?.receiverDetail };

    if (selectedRate?.pricingTier === 'SeaExport') {
      if (!selectedPickUpOption?.city?.includes('Doorstep')) {
        updatedReceiverDetail.addressLine1 = selectedPickUpOption.address;
        updatedReceiverDetail.city = selectedPickUpOption.city;
        updatedReceiverDetail.state = selectedPickUpOption.state;
        updatedReceiverDetail.postalCode = selectedPickUpOption.postalCode;
      }

      if (
        shipmentDetail?.initialReceiverAddress &&
        selectedPickUpOption?.city?.includes('Doorstep')
      ) {
        updatedReceiverDetail = {
          ...updatedReceiverDetail,
          ...shipmentDetail?.initialReceiverAddress
        };
      }
    }

    const insuranceCharge =
      (selectedInsurance.value === insurancePlans[2].value
        ? totalItemValue * 0.05
        : selectedInsurance.amount) * 100;

    const finalShipmentDetail = {
      ...saveShipmentDetail,
      pricingTier: shipmentRateModeDictionary(selectedRate?.pricingTier),
      shipmentCharge: toFixed(selectedRate?.cost),
      ...(selectedRate?.pricingTier === 'SeaExport'
        ? {
            itemPickupMode: selectedPickUpOption?.city,
            ...(!shipmentDetail?.initialReceiverAddress?.addressLine1 && {
              initialReceiverAddress: omit(shipmentDetail?.receiverDetail, [
                'name',
                'email',
                'phoneNumber'
              ])
            })
          }
        : {}),
      insuranceType: selectedInsurance.value,
      insuranceCharge,
      budgetDeliveryCharge: selectedBudgetDelivery,
      receiverDetail: updatedReceiverDetail,
      isCompleted: true,
      ...(isImportBooking(shipmentDetail)
        ? {
            lastMileCharge: toFixed(selectedLastMile?.cost),
            lastMilePricingTier: shipmentRateModeDictionary(
              selectedLastMile?.pricingTier
            )
          }
        : {}),
      ...(pickupOptionMatch
        ? {
            pickupCharge: toFixed(selectedPickupPartner?.pickupCharge),
            pickupPartner: selectedPickupPartner?.partner,
            partnerLogoUrl: selectedPickupPartner?.partnerLogoUrl,
            deliveryLocation: selectedPickupPartner?.deliveryLocation,
            ...(selectedPickupPartner?.pickupId && {
              pickupId: selectedPickupPartner?.pickupId
            })
          }
        : {})
    };
    if (!isImportBooking(shipmentDetail)) {
      delete finalShipmentDetail.lastMileCharge;
      delete finalShipmentDetail.lastMilePricingTier;
    }
    const hasBudgetDelivery =
      selectedRate.pricingTier === 'LastMileBudget' &&
      !isWithinLagos(shipmentDetail) &&
      shipmentDetail?.shipmentRoute === shipmentOptions[0].value;

    if (!hasBudgetDelivery) {
      delete finalShipmentDetail.budgetDeliveryCharge;
    }

    if (isBudgetImport && pickupPartners.length) {
      finalShipmentDetail.itemCollectionMode = shipmentMethods[1].value;
    }

    setSaveShipmentDetail({ ...finalShipmentDetail });

    if (
      shipmentDetail?.senderDetail?.countryCode !==
      shipmentDetail?.receiverDetail?.countryCode
    ) {
      setOpenInAppPrompt(true);
    } else {
      history.push(singleShipmentLinks[currentIndex + 1].link);
    }
  };

  const goBack = () => {
    history.push(singleShipmentLinks[currentIndex].initialLink);
  };

  const rates = [...shipmentRates];

  const pickupPartners = useMemo(() => {
    if (!pickupRates) {
      return [];
    }

    return [...pickupRates];
  }, [pickupRates]);

  const disabled = useMemo(() => {
    if (pickupOptionMatch) {
      return !(
        selectedInsurance.name &&
        selectedRate.mode &&
        selectedPickupPartner.partner
      );
    }

    return !(selectedInsurance.name && selectedRate.mode);
  }, [
    selectedInsurance?.name,
    selectedRate?.mode,
    pickupOptionMatch,
    selectedPickupPartner?.partner
  ]);
  const getExpressOption = rates.find(rate => rate?.pricingTier === 'Express');

  useEffect(() => {
    const { senderDetail, receiverDetail, pickupDate } = shipmentDetail;

    let senderPostalCode = null;

    let receiverPostalCode = receiverDetail?.postalCode;

    if (senderDetail?.postalCode) {
      if (selectedOption?.value === shipmentOptions[1].value) {
        senderPostalCode = senderDetail?.postalCode;
      }
    }

    if (!receiverDetail?.postalCode) {
      if (selectedOption?.value === shipmentOptions[1].value) {
        receiverPostalCode = null;
      }
    }

    const requestRatesData = {
      senderDetails: {
        cityName: senderDetail?.city,
        countryCode: senderDetail?.countryCode,
        postalCode: senderPostalCode
      },
      receiverDetails: {
        cityName: receiverDetail?.city,
        countryCode: receiverDetail?.countryCode,
        postalCode: receiverPostalCode
      },
      totalWeight: parseFloat(totalItemWeight, 10),
      lsp: businessId !== 'null' ? businessId : null
    };

    if (
      shipmentDetail?.shipmentRoute === shipmentOptions[1].value &&
      shipmentDetail?.senderDetail?.countryCode?.toUpperCase() === 'US'
    ) {
      requestRatesData.items = shipmentDetail?.items?.map(
        ({ category, weight }) => ({ category, weight })
      );
    }

    getRates(requestRatesData);

    if (pickupOptionMatch) {
      getPickupRate({
        senderDetail: {
          addressLine1: senderDetail?.addressLine1,
          addressLine2: senderDetail?.addressLine2 || '',
          addressLine3: senderDetail?.addressLine3 || '',
          city: senderDetail?.city,
          country: senderDetail?.country,
          countryCode: senderDetail?.countryCode,
          postalCode: senderDetail?.postalCode,
          state: senderDetail?.state
        },
        pickupDate: pickupDate,
        totalWeight: parseFloat(totalItemWeight, 10),
        ...(pickupPricingTier && {
          pricingTier: pickupPricingTier
        })
      });
    }

    if (isImportBooking(shipmentDetail)) {
      getLastMileRate({
        receiverDetail: {
          name: receiverDetail.name,
          email: receiverDetail.email,
          phoneNumber: receiverDetail.phoneNumber,
          addressLine1: receiverDetail?.addressLine1,
          addressLine2: receiverDetail?.addressLine2 || '',
          addressLine3: receiverDetail?.addressLine3 || '',
          city: receiverDetail?.city,
          country: receiverDetail?.country,
          countryCode: receiverDetail?.countryCode,
          postalCode: receiverDetail?.postalCode,
          state: receiverDetail?.state
        },
        totalWeight: parseFloat(totalItemWeight, 10)
      });
    }

    if (totalItemValue >= 0) {
      setIsInsurancePrompt(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pickupOptionMatch || isBudgetImport) {
      getPickupRate({
        senderDetail: {
          addressLine1: shipmentDetail?.senderDetail?.addressLine1,
          addressLine2: shipmentDetail?.senderDetail?.addressLine2 || '',
          addressLine3: shipmentDetail?.senderDetail?.addressLine3 || '',
          city: shipmentDetail?.senderDetail?.city,
          country: shipmentDetail?.senderDetail?.country,
          countryCode: shipmentDetail?.senderDetail?.countryCode,
          postalCode: shipmentDetail?.senderDetail?.postalCode,
          state: shipmentDetail?.senderDetail?.state
        },
        pickupDate: shipmentDetail?.pickupDate,
        totalWeight: parseFloat(totalItemWeight, 10),
        ...(pickupPricingTier && {
          pricingTier: pickupPricingTier
        })
      });
    }
  }, [pickupPricingTier, isBudgetImport]);

  useEffect(() => {
    if (pickupPricingTier && pickupOptionMatch) {
      const partner = pickupPartners?.find(
        partner => partner?.pricingTier === pickupPricingTier
      );

      if (partner) {
        if (selectedPickupPartner) {
          handlePickUpSelection(partner);
        }
      }
    }

    if (isBudgetImport) {
      if (!pickupPartners.length) {
        sendAlert('No Pickups available, switching to Drop-off', 'warning');

        setSaveShipmentDetail({
          ...shipmentDetail,
          itemCollectionMode: shipmentMethods[0].value
        });
      }
    }
  }, [pickupPartners]);

  useEffect(() => {
    if (history.location.state?.reValidate) {
      const isCompleted = checkIsCompleted(currentIndex, saveShipmentDetail);
      if (!isCompleted) {
        sendAlert('Please select your preferred delivery options', 'info');
      }
    }
    history.replace({
      state: {}
    });
    // eslint-disable-next-line
  }, [history.location.state?.reValidate]);

  return (
    <>
      <NewShippingStepsLayout>
        <Helmet>
          <meta
            name='description'
            content='Topship Africa Shipment Request Delivery Options'
          />
          <title>Topship Africa Shipment Request Delivery Options</title>
          <link
            rel='canonical'
            href='https://topship.africa/shipment-request-delivery-options'
          />
        </Helmet>
        <form onSubmit={handleSubmit} className='delivery-options'>
          <div className='mb-2'>
            <div
              onClick={() =>
                setOpenToggle(param => ({
                  ...param,
                  delivery: !param.delivery
                }))
              }
              className={`mb-2 toggle-container ${
                !openToggle.delivery ? 'border-shadow' : ''
              }`}
            >
              <label className='delivery-options__label mb-0'>
                Choose a Shipping Option
              </label>
              <img
                className={`arrow-icon ${
                  openToggle.delivery ? 'upside-down' : ''
                }`}
                src={SelectArrowBlue}
                alt=''
              />
            </div>
            {openToggle.delivery && (
              <DeliveryOptions1
                showLabel={false}
                loading={loading}
                rates={rates}
                selectedRate={selectedRate}
                handleRateSelection={handleRateSelection}
                handleBudgetDelivery={handleBudgetDelivery}
                selectedBudgetDelivery={selectedBudgetDelivery}
                country={shipmentDetail?.receiverDetail?.country}
                shipmentDetail={shipmentDetail}
                openSaver={() => setOpenSaverAlert(true)}
                openPickupOptionModal={openPickupOptionModal}
                setOpenPickupOptionModal={setOpenPickupOptionModal}
              />
            )}
          </div>
          {pickupOptionMatch || isBudgetImport ? (
            <div className='mb-2'>
              <div
                className={`mb-2 toggle-container ${
                  !openToggle.pickup ? 'border-shadow' : ''
                }`}
                onClick={() =>
                  setOpenToggle(param => ({
                    ...param,
                    pickup: !param.pickup
                  }))
                }
              >
                <label className='delivery-options__label mb-0'>
                  Choose Pick up Partner
                </label>
                <img
                  className={`arrow-icon ${
                    openToggle.pickup ? 'upside-down' : ''
                  }`}
                  src={SelectArrowBlue}
                  alt=''
                />
              </div>
              {openToggle.pickup && (
                <div className='delivery-options__items'>
                  {pickupLoading
                    ? [...Array(2).keys()].map(key => (
                        <div key={key} className={`delivery-options__item`}>
                          <div className='delivery-options__item__wrap discount'>
                            <div className='delivery-options__item__content w-100'>
                              <div className='delivery-options__item__name w-100'>
                                <Skeleton className='w-100' />
                              </div>
                              <div className='delivery-options__item__address w-100'>
                                <Skeleton className='w-100' />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : pickupPartners?.map((pickup, index) => {
                        const isBadImage = pickupPartnersWithBadImages.includes(
                          pickup?.partner
                        );

                        const isFez =
                          pickup?.partner === pickupPartnersWithBadImages[1];

                        return (
                          <div
                            key={index}
                            onClick={() => handlePickUpSelection(pickup)}
                            style={{ background: lightColors[index % 5] }}
                            className={`delivery-options__item${
                              selectedPickupPartner.partner === pickup.partner
                                ? ' active'
                                : ''
                            }`}
                          >
                            <div className={`delivery-options__item__radio`}>
                              <Radio
                                checked={
                                  selectedPickupPartner.partner ===
                                  pickup.partner
                                }
                                sx={{ transform: 'scale(1.5)' }}
                              />
                            </div>
                            <div className='delivery-options__item__wrap'>
                              <div className='delivery-options__item__content'>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    ...(isBadImage
                                      ? {
                                          marginLeft: isFez ? '20px' : '15px'
                                        }
                                      : {})
                                  }}
                                  className='delivery-options__item__name mb-1'
                                >
                                  <img
                                    style={{
                                      height: '26px',
                                      objectFit: 'contain',
                                      ...(isBadImage
                                        ? {
                                            transform: `scale(${isFez ? 3 : 2})`
                                          }
                                        : {})
                                    }}
                                    src={
                                      pickup?.partnerLogoUrl ||
                                      'https://upload.wikimedia.org/wikipedia/commons/c/ce/Example_image.png'
                                    }
                                    alt={pickup?.partner}
                                    data-default-src='https://upload.wikimedia.org/wikipedia/commons/c/ce/Example_image.png'
                                  ></img>
                                  <h3
                                    className='mb-0'
                                    style={{
                                      paddingLeft: isFez
                                        ? '35px'
                                        : isBadImage
                                        ? '25px'
                                        : '5px'
                                    }}
                                  >
                                    {pickup.partner}
                                  </h3>
                                </div>
                                <div
                                  className='delivery-options__item__address'
                                  style={{
                                    ...(isFez
                                      ? {
                                          position: 'relative',
                                          zIndex: '9999999'
                                        }
                                      : {})
                                  }}
                                >
                                  <span>
                                    {pickup?.duration ||
                                      getPlan('pickup', pickup?.partner)
                                        ?.pickupDeliveryTime}
                                  </span>
                                </div>
                              </div>
                              <div className='delivery-options__item__price'>
                                <span>
                                  {toCurrency(pickup?.pickupCharge / 100)}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  {!pickupRates.length && !pickupLoading ? (
                    <EmptyList
                      emptyIcon={emptyStateIcon}
                      heading='No Available pickup partner'
                    />
                  ) : null}
                </div>
              )}
            </div>
          ) : null}
          {isImportBooking(shipmentDetail) ? (
            <div className='mb-2'>
              <div
                className={`mb-2 toggle-container ${
                  !openToggle.lastMile ? 'border-shadow' : ''
                }`}
                onClick={() =>
                  setOpenToggle(param => ({
                    ...param,
                    lastMile: !param.lastMile
                  }))
                }
              >
                <label className='delivery-options__label mb-0'>
                  Choose a Delivery Partner
                </label>
                <img
                  className={`arrow-icon ${
                    openToggle.lastMile ? 'upside-down' : ''
                  }`}
                  src={SelectArrowBlue}
                  alt=''
                />
              </div>
              {openToggle.lastMile && (
                <>
                  <p
                    style={{
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#545859',
                      fontWeight: 500
                    }}
                  >
                    Your delivery location is out of <b>Lagos, Nigeria.</b>{' '}
                    Please select a delivery method to get it delivered to you
                  </p>

                  <DeliveryOptions1
                    showLabel={false}
                    loading={lastMileLoading}
                    rates={lastMileRates}
                    selectedRate={selectedLastMile}
                    handleRateSelection={handleLastMileSelection}
                    isLastMile={isImportBooking(shipmentDetail)}
                    shipmentDetail={shipmentDetail}
                  />
                </>
              )}
            </div>
          ) : null}
          <div className='mb-2'>
            <div
              className={`mb-2 toggle-container ${
                !openToggle.insurance ? 'border-shadow' : ''
              }`}
              onClick={() =>
                setOpenToggle(param => ({
                  ...param,
                  insurance: !param.insurance
                }))
              }
            >
              <label className='delivery-options__label'>
                Choose an Insurance Option
              </label>
              <img
                className={`arrow-icon ${
                  openToggle.insurance ? 'upside-down' : ''
                }`}
                src={SelectArrowBlue}
                alt=''
              />
            </div>
            {openToggle.insurance && (
              <div>
                {isInsurancePrompt && (
                  <Alert
                    severity={'info'}
                    sx={{
                      fontSize: '1.5rem',
                      fontFamily: 'General Sans',
                      backgroundColor: '#e6f2fe',
                      color: '#22428F',
                      border: 'none',
                      marginBottom: '20px',
                      '& .MuiAlert-icon': {
                        color: '#22428F'
                      }
                    }}
                    onClose={() => setIsInsurancePrompt(false)}
                  >
                    We recommend purchasing Premium
                    {totalItemValue >= extendedInsuranceLimits.min
                      ? '/Extended'
                      : ''}{' '}
                    Insurance to protect your goods in transit.
                  </Alert>
                )}
                <div className='delivery-options__items'>
                  {insurancePlans
                    .filter(
                      (_, index) =>
                        totalItemValue >= extendedInsuranceLimits.min ||
                        index < insurancePlans.length - 1
                    )
                    .map((plan, index) => (
                      <div
                        key={plan.value}
                        onClick={() => handleInsuranceSelection(plan)}
                        style={{ background: lightColors[index % 5] }}
                        className={`delivery-options__item${
                          selectedInsurance.name === plan.name ? ' active' : ''
                        }`}
                      >
                        <div className={`delivery-options__item__radio`}>
                          <Radio
                            checked={selectedInsurance.name === plan.name}
                            sx={{ transform: 'scale(1.5)' }}
                          />
                        </div>
                        <div className='delivery-options__item__wrap'>
                          <div className='delivery-options__item__content'>
                            <div className='delivery-options__item__name'>
                              <h3>{plan.name}</h3>
                            </div>
                            <div className='delivery-options__item__address'>
                              <span>{plan.summary}</span>
                            </div>
                          </div>
                          <div className='delivery-options__item__price'>
                            <span>
                              {plan?.price
                                ? toCurrency(plan?.price(totalItemValue))
                                : plan.amount
                                ? toCurrency(plan.amount)
                                : plan.cost}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
          {/* <FormControlLabel
          label='Add free returns to this shipment'
          className='delivery-options__checkbox'
          control={<Checkbox />}
        /> */}
          <div className='delivery-options__footer'>
            <div onClick={goBack} className='delivery-options__footer__action'>
              Go Back
            </div>
            <SubmitButton text='Continue' disabled={disabled} />
          </div>
        </form>
      </NewShippingStepsLayout>
      <TermsAndConditionsPrompt
        openPrompt={openInAppPrompt}
        setOpenPrompt={setOpenInAppPrompt}
        openOrderSummary={() =>
          history.push(singleShipmentLinks[currentIndex + 1].link)
        }
      />
      <InAppSaverPrompt
        openPrompt={openSaverAlert}
        setOpenPrompt={setOpenSaverAlert}
        selectNewService={() => {
          setSelectedRate(getExpressOption || {});
          setOpenSaverAlert(false);
        }}
      />
      <SeaFreightPickupPrompt
        openPrompt={openPickupOptionModal}
        setOpenPrompt={setOpenPickupOptionModal}
        selectedPickUpOption={selectedPickUpOption}
        setSelectedPickUpOption={setSelectedPickUpOption}
      />
    </>
  );
};

export default DeliveryOptions;
