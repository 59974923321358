import React from 'react';
import { ReactComponent as InfoIcon } from '../assets/svg/how-it-works-icon.svg';

const HowItWorksTrigger = ({ setOpenHowItWorks }) => {
  return (
    <button
      className='see__how-it--works--cta'
      onClick={() => setOpenHowItWorks(true)}
    >
      <InfoIcon style={{ marginRight: '1rem' }} />
      See how it works
    </button>
  );
};

export default HowItWorksTrigger;
