export const getCountryTags = () => {
  return {
    US: 'UAC-mujeq',
    UK: 'UAC-TS',
    CA: 'Topship',
    CHINA: '邓涛topship'
  };
};

export const standardOptions = ['UK', 'US', 'CA'];

export const shippingFromOptions = [
  {
    value: 'UK',
    label: 'United Kingdom',
    origin: 'UAC-TS'
  },
  {
    value: 'US',
    label: 'United States',
    origin: 'UAC-mujeq'
  },
  {
    value: 'CA',
    label: 'Canada',
    origin: 'Topship'
  },
  {
    value: 'AU',
    label: 'Australia',
    origin: 'FWS',
    address: 'AIC 56a anzac st chullora, Australia NSW 2190'
  },
  {
    value: 'BD',
    label: 'Bangladesh',
    origin: 'DAC',
    address: 'House-13, Road-7, Sector-1, Uttara, Dhaka, Bangladesh 1230'
  },
  {
    value: 'BG',
    label: 'Bulgaria',
    origin: 'SOF',
    address: '3, Capitan Dimitar Spisarevsky street, Sofia, Bulgaria 1592'
  },
  {
    value: 'CN',
    label: 'China',
    origin: 'SHA',
    address:
      'Unit 1, Building 15, Lane 248 Huqingping Highway, Minhang District, Shanghaie, China 201105'
  },
  {
    value: 'CY',
    label: 'Cyprus',
    origin: 'NIC',
    address:
      'Leoforos Spyrou Kyprianou NO.17, Limassol, Mesa Geitonia, Cyprus 4001'
  },
  {
    value: 'CZ',
    label: 'Czech Republic',
    origin: 'PRG',
    address:
      'ARAMEX CZ, s.r.o. Ke Kopanine 577, Tuchomerice, Stredocesky kraj, Czech Republic 25267'
  },
  {
    value: 'EG',
    label: 'Egypt',
    origin: 'CAI',
    address: 'Aramex, outbound, SNS 4,5 KM From Cairo-Suez Road, Egypt'
  },
  {
    value: 'FR',
    label: 'France',
    origin: 'PAR',
    address:
      'GL - NET 7 Rue du Meunier, Roissy-en-France, Ile-de-France, France, 95700'
  },
  {
    value: 'GE',
    label: 'Georgia',
    origin: 'TBS',
    address: '#1 Stefane Chrelashvili St, Tbilisi, Isani, Georgia 6000'
  },
  {
    value: 'DE',
    label: 'Germany',
    origin: 'FRX',
    address:
      'eCom Postfachservice Robert Koch Strasse 50, Geb.H, Mainz, Germany 55129'
  },
  {
    value: 'GR',
    label: 'Greece',
    origin: 'ATH',
    address: 'Aramex, L.Varis-Koropiou 137, Koropi, West Attica, Greece 19400'
  },
  {
    value: 'HK',
    label: 'Hong Kong',
    origin: 'HKG',
    address:
      'Aramex HK LTD - SNS - Unit 2, 1/F, Goodman Interlink, 39 Tsing Yi Road, Tsing Yi, New Territories, Hong Kong'
  },
  {
    value: 'IN',
    label: 'India',
    origin: 'BOM',
    address:
      'Aramex India Pvt Ltd. Plot No. B-4, Cross Road B, Marol MIDC, Andheri (E), Mumbai, Maharashtra, India 400093'
  },
  {
    value: 'ID',
    label: 'Indonesia',
    origin: 'JKT',
    address:
      'Jl.Penjernihan 1 No.1 Rt.06 Rw.06 Bendungan Hilir kec. Tanah Abang, Jakarta, DKI JAKARTA, Indonesia'
  },
  {
    value: 'IT',
    label: 'Italy',
    origin: 'MIL',
    address: 'EuroLanes, via del Gaggiolo, 38, Arcene, Bergamo, Italy 24040'
  },
  {
    value: 'JP',
    label: 'Japan',
    origin: 'TYO',
    address:
      'AIC X FRONTIER WEST 5F, 3-2-9, SHINSUNA, Koto-ku, Tokyo, Japan 136-8651'
  },
  {
    value: 'JO',
    label: 'Jordan',
    origin: 'AMM',
    address:
      'Aramex main building, Alquds street, Almugablain district, Amman, Jordan'
  },
  {
    value: 'KW',
    label: 'Kuwait',
    origin: 'KWI',
    address:
      'Sulibiya industrial area, block 2, Agility complex, warehouse number 26K-SNS, Sulibiya, Jahra, Kuwait 13088'
  },
  {
    value: 'LB',
    label: 'Lebanon',
    origin: 'BEY',
    address: 'Mirna Chalouhi Highway, Aramex Bldg, Ad Dikwanah, Lebanon 55606'
  },
  {
    value: 'MY',
    label: 'Malaysia',
    origin: 'KUL',
    address:
      'Aramex, Hedgeford Innovation Park, 12C, Jalan Tandang, Petaling Jaya, Selangor, Malaysia 46050'
  },
  {
    value: 'MA',
    label: 'Morocco',
    origin: 'CAS',
    address:
      'aramex, Boulevard Chefchaouni, Q.I Ain Sebaa, SB 8 Bd New York, Lot. Maritanie Parc Logistique Soft, Casablanca, Morocco 20590'
  },
  {
    value: 'NL',
    label: 'Netherlands',
    origin: 'EIN',
    address:
      'Aramex Nederland BV, Mispelhoefstraat 33A, Eindhoven, Noord Brabant, Netherlands 5651 GK'
  },
  {
    value: 'NZ',
    label: 'New Zealand',
    origin: 'AKL',
    address:
      'Fastway Couriers Auckland, 118 Hugo Johnston Dr, Penrose, Auckland, New Zealand 1061'
  },
  {
    value: 'OM',
    label: 'Oman',
    origin: 'MCT',
    address:
      'Building No. 175, Way # 4203, Al-Azaiba North – Muscat, Al-Watan Commercial Complex, Muscat, Oman'
  },
  {
    value: 'PK',
    label: 'Pakistan',
    origin: 'KHX',
    address:
      'BlueEx, Aramex, Plot No. S-1 Sector 6-C, Opposite Jamia Darul Uloom, Siddiq Akbar Road, Mehran Town Korangi, Karachi, Sindh, Pakistan 74900'
  },
  {
    value: 'PH',
    label: 'Philippines',
    origin: 'MNL',
    address:
      'RAF International Forwarding Philippines Inc. Bldg 2 Units 3 & 4 Oyster Industrial Complex, NAIA Avenue-SNS, Paranaque, Metro Manila, Philippines 1700'
  },
  {
    value: 'QA',
    label: 'Qatar',
    origin: 'DOH',
    address:
      'Zone No. 41, Rawdat Al Khail St., Street No. 330, Building No. 131, Doha, Qatar'
  },
  {
    value: 'SA',
    label: 'Saudi Arabia',
    origin: 'RUH',
    address:
      'Aramex Building, RLP WH#3 – Gate#4, AlSafa St, Silay District, Riyadh, Saudi Arabia'
  },
  {
    value: 'SG',
    label: 'Singapore',
    origin: 'SIN',
    address:
      'AIC Department at 3 Changi South Street 1, #01-01, Singapore 486795'
  },
  {
    value: 'ZA',
    label: 'South Africa',
    origin: 'JNB',
    address:
      'Aramex No. 1 Quark Crescent, Linbro Business Park, Sandton, Gauteng, South Africa 2065'
  },
  {
    value: 'KR',
    label: 'South Korea',
    origin: 'SEL',
    address:
      '832, Baekbeom-ro, Seo-gu, Incheon, Republic of Korea, Seo-gu, Incheon, South Korea 22839'
  },
  {
    value: 'ES',
    label: 'Spain',
    origin: 'BCN',
    address:
      'MRW Internacional C/ Garrigues 45, Pol Ind Mas Blau II, Sant Boi de Llobregat, Cataluna, Spain 08830'
  },
  {
    value: 'LK',
    label: 'Sri Lanka',
    origin: 'CMB',
    address:
      '745/50 - 1/1, Dr. Danister De Silva Mawatha, Colombo, Sri Lanka 00900'
  },
  {
    value: 'CH',
    label: 'Switzerland',
    origin: 'GVA',
    address:
      'Aramex (AIC), Voie-des-Traz 20, CP1122, Geneva 5, Geneva, Switzerland 1211'
  },
  {
    value: 'TH',
    label: 'Thailand',
    origin: 'BKK',
    address: '335 Pattanakarn Road, Prawet District, Bangkok, Thailand 10250'
  },
  {
    value: 'TN',
    label: 'Tunisia',
    origin: 'TUN',
    address: 'KM 2 route de Megrine Saint Gobain, Tunis, Tunisia'
  },
  {
    value: 'TR',
    label: 'Turkey',
    origin: 'IST',
    address:
      'Aramex International Hava Kargo ve Kurye A.S, 15 Temmuz Mahallesi, Gülbahar Caddesi No: 19/3 B-C-D Blok Bagcilar, Istanbul, Turkey 34212'
  },
  {
    value: 'AE',
    label: 'United Arab Emirates',
    origin: 'DXB',
    address:
      'Aramex Building, 184 Airport Road, Umm Ramool, POBOX 35962, Dubai, United Arab Emirates'
  }
];

export const productOptions = [
  {
    label: 'Not an electric device or gadget'
  },
  {
    label: 'An electric device or gadget'
  }
];

export const getShippingFrom = (shippingFrom = '') => {
  if (!shippingFrom) {
    return null;
  }

  return shippingFromOptions.find(option => option.value === shippingFrom);
};

export const isStandard = (shippingFrom = '') => {
  if (!shippingFrom) {
    return false;
  }

  return standardOptions.includes(shippingFrom);
};
