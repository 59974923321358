import React, { useState } from 'react';
import { ReactComponent as OpenAccordion } from '../assets/svg/open-accordion.svg';
import { ReactComponent as CloseAccordion } from '../assets/svg/close-accordion.svg';

const HowItWorksAccordion = ({ content }) => {
  const [openAccordion, setOpenAccordion] = useState(false);

  return (
    <div
      className={`how__it-works--accordion-container ${
        openAccordion ? '' : 'accordion__closed'
      }`}
      onClick={() => setOpenAccordion(!openAccordion)}
    >
      <div className='how__it-works--accordion-title-box'>
        <p
          className={`accordion__title-text ${
            openAccordion ? ' ' : 'closed__text'
          }`}
        >
          {content.question}
        </p>
        {openAccordion ? (
          <CloseAccordion style={{ minheight: '1.4rem', minWidth: '1.4rem' }} />
        ) : (
          <OpenAccordion style={{ minheight: '1.4rem', minWidth: '1.4rem' }} />
        )}
      </div>
      {openAccordion ? (
        <div className='accordion__body-text--box'>
          <p className='accordion__body-text'>{content.answer}</p>
        </div>
      ) : null}
    </div>
  );
};

export default HowItWorksAccordion;
