import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import roundUpSocials from '../../constants/roundup-socials';
import { useGetUserPayload } from '../../utilities/get-payload';
import { useSendAlert } from '../../utilities/send-alert';
import ExportRoundUpPrompt from '../modals/export-roundup-modal';
import RateRoundUpPrompt from '../modals/rate-roundup-modal';
import YearRoundUpPrompt from '../modals/round-up-modal';
import ShippedTotalRoundUpPrompt from '../modals/shipment-total-roundup-modal';
import ShopnshipRoundUpPrompt from '../modals/shopnship-roundup-modal';
import { useScreenshot } from 'use-react-screenshot';
import BusyOverlay from '../busy-overlay';
import YearRoundUp from '../modals/year-round-up';

const RoundupModalWrapper = ({ openRoundUpPrompt, setOpenRoundUpPrompt }) => {
  const user = useGetUserPayload();
  const sendAlert = useSendAlert();
  const [openShippedTotalPrompt, setShipmentTotalPrompt] = useState(false);
  const [openExportTotalPrompt, setExportTotalPrompt] = useState(false);
  const [openRateTotalPrompt, setRateTotalPrompt] = useState(false);
  const [openShopnshipRoundUpPrompt, setShopnshipRoundUpPrompt] = useState(
    false
  );
  const [fileLoading, setFileLoading] = useState(false);
  const [downloadDisplay, setDownloadDisplay] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [isUploadedImage, setIsUploadedImage] = useState(false);
  const [image, takeScreenshot] = useScreenshot();
  const loading = fileLoading;
  const filename = `${user.fullName?.split(' ')?.join('_')}_Roundup.png`;

  const getImage = html => {
    takeScreenshot(html);
    setIsUploadedImage(true);
  };

  const handleCopyLink = useCallback(
    link => {
      if (window.navigator) {
        window.navigator.clipboard.writeText(link);
        sendAlert('Copied to Clipboard!', 'success');
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = link;
        textArea.style = {
          top: '0',
          left: '0',
          position: 'fixed'
        };
        window.document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        const success = window.document.execCommand('copy');
        if (success) {
          sendAlert('Copied to Clipboard!', 'success');
        } else {
          sendAlert("Couldn't copy to Clipboard.", 'error');
        }
      }
    },
    [sendAlert]
  );

  const handleShareLink = useCallback(
    async social => {
      switch (social) {
        case roundUpSocials.facebook:
          window
            .open(`https://web.facebook.com/sharer?u=${imageUrl}`, '_blank')
            .focus();
          break;
        case roundUpSocials.whatsapp:
          window
            .open(
              `https://api.whatsapp.com/send?text=${encodeURIComponent(
                `Hey! Check out my year in shipping on Topship, ${imageUrl}`
              )}`,
              '_blank'
            )
            .focus();
          break;
        case roundUpSocials.twitter:
          window
            .open(
              `https://twitter.com/intent/tweet?url=${imageUrl}&text=${encodeURIComponent(
                'Hey! Check out my year in shipping on Topship #TopshipRoundUp, '
              )}`,
              '_blank'
            )
            .focus();
          break;
        case roundUpSocials.download:
          const link = document.createElement('a');
          link.href = imageUrl;
          link.download = filename;
          link.click();

          break;
        case roundUpSocials.hyperLink:
          handleCopyLink(
            `Hey! Check out my year in shipping on Topship #TopshipRoundUp, ${imageUrl}`
          );
          break;
        default:
          break;
      }
    },
    [handleCopyLink, imageUrl, filename]
  );

  const handleUploadImage = useCallback(
    file => {
      setFileLoading(true);
      const url = `${process.env.REACT_APP_REST_API}/upload`;
      const formData = new FormData();

      formData.append('file', file);
      formData.append('fileName', file.name);
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      axios
        .post(url, formData, config)
        .then(response => {
          setIsUploadedImage(false);
          setFileLoading(false);
          setImageUrl(response.data.Location);
          setDownloadDisplay(true);
        })
        .catch(err => {
          setIsUploadedImage(false);
          setFileLoading(false);
          sendAlert(err?.message, 'error');
        });
    },
    [sendAlert]
  );

  const handleShareImage = useCallback(
    async image => {
      const response = await fetch(image);
      const blob = await response.blob();
      const file = new File([blob], filename, {
        type: 'image/png'
      });
      try {
        if (window.navigator) {
          const shareData = {
            files: [file],
            title: filename,
            text: `Hey! Check out my year in shipping on Topship`
          };
          if (
            window.navigator.canShare({ files: [file] }) &&
            window.innerWidth < 768 // allow share for mobile screens only
          ) {
            window.navigator
              .share(shareData)
              .finally(() => {
                window.location.reload();
              })
              .catch(() => {
                window.location.reload();
              });
          } else {
            handleUploadImage(file);
          }
        } else {
          handleUploadImage(file);
        }
      } catch (error) {
        handleUploadImage(file);
      }
    },
    [handleUploadImage, filename]
  );

  const closeSocials = () => {
    setDownloadDisplay(false);
  };

  const promptProps = {
    downloadDisplay,
    setDownloadDisplay,
    getImage,
    handleShareLink,
    closeSocials,
    imageUrl,
    setImageUrl
  };

  useEffect(() => {
    if (image && isUploadedImage) {
      handleShareImage(image);
    }
  }, [image, isUploadedImage, handleShareImage]);

  return (
    <>
      {openRoundUpPrompt && (
        // <YearRoundUpPrompt
        //   openPrompt={openRoundUpPrompt}
        //   setOpenPrompt={setOpenRoundUpPrompt}
        //   setShippedTotal={setShipmentTotalPrompt}
        //   setShopnshipTotal={setShopnshipRoundUpPrompt}
        //   setExportTotal={setExportTotalPrompt}
        //   setRateTotal={setRateTotalPrompt}
        // />
        <YearRoundUp
          {...promptProps}
          openPrompt={openRoundUpPrompt}
          setOpenPrompt={setOpenRoundUpPrompt}
          setExportTotal={setExportTotalPrompt}
        />
      )}
      {openShippedTotalPrompt && (
        <ShippedTotalRoundUpPrompt
          {...promptProps}
          openPrompt={openShippedTotalPrompt}
          setOpenPrompt={setShipmentTotalPrompt}
          setOpenMainPrompt={setOpenRoundUpPrompt}
        />
      )}
      {openShopnshipRoundUpPrompt && (
        <ShopnshipRoundUpPrompt
          {...promptProps}
          openPrompt={openShopnshipRoundUpPrompt}
          setOpenPrompt={setShopnshipRoundUpPrompt}
          setOpenMainPrompt={setOpenRoundUpPrompt}
        />
      )}
      {openExportTotalPrompt && (
        <ExportRoundUpPrompt
          {...promptProps}
          openPrompt={openExportTotalPrompt}
          setOpenPrompt={setExportTotalPrompt}
          setOpenMainPrompt={setOpenRoundUpPrompt}
        />
      )}
      {openRateTotalPrompt && (
        <RateRoundUpPrompt
          {...promptProps}
          openPrompt={openRateTotalPrompt}
          setOpenPrompt={setRateTotalPrompt}
          setOpenMainPrompt={setOpenRoundUpPrompt}
        />
      )}
      <BusyOverlay loading={loading} speed='1.7s' />
    </>
  );
};

export default RoundupModalWrapper;
